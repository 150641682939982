import { useContext, useState } from 'react';
import { API_ROUTES } from '../../data/constants';
import { AuthContext } from '../../App';
import { FormattedMessage } from 'react-intl';

export default function ForgotPassword() {
  const [resetError, setResetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mailSent, setMailSent] = useState(false);
  const [dataMessage, setDataMessage] = useState('');
  const { locale } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const formData = { email: email, locale: locale };
    handleReset(formData);
  };

  const handleReset = async (formData) => {
    try {
      const clientResetAttempt = await fetch(`${API_ROUTES.RESET_PWD}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(formData),
      });

      if (!clientResetAttempt.ok) {
        const errorData = await clientResetAttempt.json();
        throw new Error(errorData.error || 'Password reset failed.');
      }

      const data = await clientResetAttempt.json();
      setDataMessage(data.message);
      setMailSent(true);
    } catch (error) {
      console.error('Error during password reset:', error);
      setResetError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="form-container loginForm">
      {mailSent ? (
        <div>{dataMessage}</div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="form-group"
        >
          <div className="label-form-div">
            <label
              htmlFor="email"
              className="formLabel"
            >
              <FormattedMessage
                id="loginform.email"
                defaultMessage={'Email'}
              />{' '}
              <span className="mandatoryStar">*</span>
            </label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              required={true}
            />
            {resetError && (
              <span className="mandatoryStar">{errorMessage}</span>
            )}
          </div>
          <button
            type="submit"
            className="form-submit-btn"
          >
            <FormattedMessage
              id="forgotpassword.button"
              defaultMessage={'Reset Password'}
            />
          </button>
        </form>
      )}
    </div>
  );
}
