import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_ROUTES } from '../../data/constants';
import Loader from '../common/loading';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from '../../App';

export default function VerifyEmailComponent() {
  const { token } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { locale } = useContext(AuthContext);
  // console.log('VerifyEmailComponentCalled');
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${API_ROUTES.VERIFY_EMAIL}/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setIsSuccess(true);
          setIsLoading(false);
        } else {
          throw new Error(
            'Failed to verify email due to server response',
            response
          );
        }
      } catch (error) {
        // console.error('Failed to verify email:', error);
        setIsLoading(false);
        throw new Error('Failed to verify email due to error', error);
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setIsLoading(false);
    }
  }, [token]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <p>
          <FormattedMessage
            id="verifyEmail.success"
            defaultMessage="Your email has been successfully verified!"
          />
        </p>
      ) : (
        <p>
          <FormattedMessage
            id="verifyEmail.failure"
            defaultMessage="Failed to verify email. Please try again or contact support."
          />
          <Link to={getLocalizedPath('/account/General')}>
            {' '}
            <FormattedMessage
              id="verifyEmail.gotoaccount"
              defaultMessage="Go to my account"
            />
          </Link>
        </p>
      )}
    </div>
  );
}
