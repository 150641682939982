import { FormattedMessage } from 'react-intl';

import CheckoutComponent from '../components/basketcomponents/checkoutcomponent';
import { useContext } from 'react';
import { AuthContext } from '../App';
import LoginForm from '../components/login/logincomponent';
import OrderStepsComponent from '../components/ordersteps/orderstepscomponent';
import ScrollToTop from '../components/common/scrolltotop';
export default function CheckoutPage() {
  const { socket } = useContext(AuthContext);
  ScrollToTop();
  return (
    <div className="content-div">
      <OrderStepsComponent stepNumber={2} />
      <h1>
        {' '}
        <FormattedMessage
          id="checkout.title"
          defaultMessage="Checkout"
        />
      </h1>
      <CheckoutComponent />
    </div>
  );
}
