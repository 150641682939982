import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function PrivacyComponent() {
  return (
    <div className="privacy-container">
      <h1>
        <FormattedMessage
          id="privacy.title"
          defaultMessage="Privacy Policy"
        />
      </h1>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.introduction.title"
            defaultMessage="Introduction"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.introduction.content"
            defaultMessage="We value your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and safeguard your information when you visit our website."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.dataCollection.title"
            defaultMessage="Data Collection"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.dataCollection.content"
            defaultMessage="We collect personal data that you provide to us when you create an account, make a purchase, or contact us. This data may include your name, email address, phone number, and payment information. We use this information to process your orders, provide customer service, and improve our services."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.cookies.title"
            defaultMessage="Cookies"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.cookies.content"
            defaultMessage="Our website uses cookies to enhance your experience. We use two types of cookies: essential cookies and performance cookies. Essential cookies are necessary for the operation of our website, including Stripe functionality. Performance cookies are used for performance improvements, metrics, website usage, and statistics, but do not gather any personal information."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.dataHosting.title"
            defaultMessage="Data Hosting"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.dataHosting.content"
            defaultMessage="Your data is hosted within the European Union on MongoDB's Atlas infrastructures. Our website is hosted at OVHCloud in France. This ensures that your data is stored in compliance with EU data protection regulations."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.dataSecurity.title"
            defaultMessage="Data Security"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.dataSecurity.content"
            defaultMessage="We implement a variety of security measures to maintain the safety of your personal data. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.thirdParties.title"
            defaultMessage="Third-Party Services"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.thirdParties.content"
            defaultMessage="We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.userRights.title"
            defaultMessage="Your Rights"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.userRights.content"
            defaultMessage="You have the right to access, correct, or delete your personal data. You also have the right to restrict or object to the processing of your data, and the right to data portability. To exercise any of these rights, please contact us at "
          />
          <a href="mailto:informatique@rivierabyata.com">
            informatique@rivierabyata.com
          </a>
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="privacy.changes.title"
            defaultMessage="Changes to this Privacy Policy"
          />
        </h2>
        <p>
          <FormattedMessage
            id="privacy.changes.content"
            defaultMessage="We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes."
          />
        </p>
      </section>
    </div>
  );
}
