import { useContext, useState } from 'react';
import { API_ROUTES } from '../../data/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthContext } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';

export default function ResetPassword() {
  const [resetError, setResetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [dataMessage, setDataMessage] = useState('');
  const { id } = useParams(); // This retrieves the `:id` parameter from the URL
  const intl = useIntl();
  const { locale } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [error, setError] = useState(false);
  const handleReset = async (formData) => {
    try {
      const clientResetAttempt = await fetch(`${API_ROUTES.RESET_PWD}/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(formData),
      });
      if (!clientResetAttempt.ok) {
        const errorData = await clientResetAttempt.json();
        setResetError(true);
        // console.log(errorData);
        setErrorMessage(errorData.error);
        throw new Error(`Failed to reset password : ${errorData.error}`);
      } else {
        const data = await clientResetAttempt.json();
        setDataMessage(data.message);
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to reset password : ${error}`);
    }
  };

  const handlePasswordChange = (event) => {
    const newValue = event.target.value;
    setPassword(newValue);
    setError(
      newValue.length > 0 &&
        passwordCheck.length > 0 &&
        newValue !== passwordCheck
    );
  };

  const handlePasswordCheckChange = (event) => {
    const newValue = event.target.value;
    setPasswordCheck(newValue);
    setError(
      newValue.length > 0 && password.length > 0 && newValue !== password
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (error) {
      alert(
        intl.formatMessage({
          id: 'signupform.passwordmismatch',
          defaultMessage: 'Passwords must match',
        })
      );
      return;
    } else {
      const password = event.target.password.value;

      const formData = {
        password: password,
        locale: locale,
      };
      handleReset(formData);
    }
  };

  return (
    <div className="form-container loginForm">
      {' '}
      {success ? (
        <p>{dataMessage}</p>
      ) : (
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="form-group"
        >
          <div className="label-form-div">
            <label
              htmlFor="password"
              className="formLabel"
            >
              <FormattedMessage
                id="signupform.password"
                defaultMessage="Password"
              />
              <span className="mandatoryStar">*</span>
            </label>{' '}
            <input
              className="input"
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required={true}
            />{' '}
            <button
              type="button"
              onClick={toggleShowPassword}
              className="eye-button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
            {error && (
              <div style={{ color: 'red' }}>
                <FormattedMessage
                  id="signupform.passwordmismatch"
                  defaultMessage="Passwords must match."
                />
              </div>
            )}
          </div>{' '}
          <div className="label-form-div">
            <label
              htmlFor="password-check"
              className="formLabel"
            >
              <FormattedMessage
                id="signupform.confirmpassword"
                defaultMessage="Confirm Password"
              />
              <span className="mandatoryStar">*</span>
            </label>{' '}
            <input
              className="input"
              type={showPassword ? 'text' : 'password'}
              id="password-check"
              name="password-check"
              value={passwordCheck}
              onChange={handlePasswordCheckChange}
              required={true}
            />
            {error && (
              <div style={{ color: 'red' }}>
                <FormattedMessage
                  id="signupform.passwordmismatch"
                  defaultMessage="Passwords must match."
                />
              </div>
            )}
            <button
              type="button"
              onClick={toggleShowPassword}
              className="eye-button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="label-form-div">
            <button
              type="submit"
              className="form-submit-btn"
            >
              <FormattedMessage
                id="forgotpassword.resetpassword"
                defaultMessage="Reset password"
              />
            </button>{' '}
            <p>
              <span className="mandatoryStar">*</span>{' '}
              <FormattedMessage
                id="signupform.mandatoryfields"
                defaultMessage="Mandatory fields."
              />
            </p>
          </div>
          {resetError ? <p>{errorMessage}</p> : ''}
        </form>
      )}
    </div>
  );
}
