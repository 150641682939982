export default function removeProductCompletely(productId, callback, socket) {
  const existingProducts = JSON.parse(localStorage.getItem('products')) || [];
  // Filter out the product to be removed
  const updatedProducts = existingProducts.filter(
    (product) => product.productId !== productId
  );

  localStorage.setItem('products', JSON.stringify(updatedProducts));

  if (socket) {
    const tempBasket =
      updatedProducts.length === 0
        ? []
        : {
            tempBasketDate: new Date(), // Ajout de la date actuelle
            tempBasketProducts: updatedProducts.map((product) => ({
              productId: product.productId,
              productQuantity: product.productQuantity,
            })),
          };

    socket.emit('update-basket', tempBasket);
  }

  // Execute the callback function if provproductIded
  if (callback) callback();
}
