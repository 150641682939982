import { FormattedMessage } from 'react-intl';
import ProductComponent from '../components/productcomponents/productcomponent';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../App';
import Carousel from '../components/productcomponents/productcarousel';

export default function ProductPage() {
  const { products } = useContext(AuthContext);
  const currentProductId = useLocation().pathname.split('/')[2];
  const currentProductObject = products.find(
    (product) => product._id === currentProductId
  );
  return (
    <div className="product-parent-div content-div">
      <ProductComponent />
    </div>
  );
}
