import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function SignUpOkay() {
  return (
    <div className="content-div">
      <h1>
        <FormattedMessage
          id="signupokay.thankyou"
          defaultMessage="Thank you!"
        />
      </h1>
      <p>
        <FormattedMessage
          id="signupokay.message"
          defaultMessage="Thank you for signing up. A verification link has been sent to your email address. Please confirm your email."
        />
      </p>
    </div>
  );
}
