// import CategoriesComponent from '../components/categoriescomponent/categoriescomponent';
import CategoryComponent from '../components/categorycomponent/categorycomponent';
// import TeamComponent from '../components/teamcomponents/teamcomponent';

export default function CategoryPage() {
  return (
    <div className="content-div">
      <CategoryComponent />
    </div>
  );
}
