import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function AddProductBasket(productId, callback, socket) {
  // Fetch the existing products from localStorage and parse them into an array.
  // If there's no product array yet, default to an empty array.

  const existingProducts = JSON.parse(localStorage.getItem('products')) || [];

  const productIndex = existingProducts.findIndex(
    (product) => product.productId === productId
  );

  try {
    if (productIndex !== -1) {
      existingProducts[productIndex].productQuantity += 1;
    } else {
      existingProducts.push({ productId: productId, productQuantity: 1 });
    }
    sendKeyEvent({
      eventCategory: 'E-Commerce',
      eventAction: 'Add to Cart',
      eventLabel: productId,
    });
    localStorage.setItem('products', JSON.stringify(existingProducts));
    if (socket) {
      const tempBasket = {
        tempBasketDate: new Date(), // Ajout de la date actuelle
        tempBasketProducts: existingProducts.map((product) => ({
          productId: product.productId,
          productQuantity: product.productQuantity,
        })),
      };

      socket.emit('update-basket', tempBasket);
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
    // console.log('Produit Ajouté au panier');
  } catch (error) {
    throw new Error(error);
  }
}
