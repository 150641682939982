import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../App';
import Loader from '../common/loading';
import { FILES_URL } from '../../data/constants';
import CreateProductPreviewCard from '../productcardscomponent/productcardscomponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import GoToBasketLine from '../common/gotobasketcomponent';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function CategoryComponent() {
  const { products, categories, locale } = useContext(AuthContext);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [category, setCategory] = useState({});
  const { id: paramsId } = useParams();
  const id = paramsId;
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  useEffect(() => {
    if (products.length > 0 && categories.length > 0) {
      const categoryFiltered = categories.find(
        (category) => category._id === id
      );
      const productsCat = products.filter((product) => product.category === id);

      setCategoryProducts(productsCat);
      setCategory(categoryFiltered);
    }
  }, [products, categories]);

  const title = category[`title_${locale}`];
  const color = category.color;
  return (
    <div className="categories-page-div">
      <Link
        to={getLocalizedPath('/')}
        className="back-to-home-link"
        style={{ '--color': color }}
        onClick={(e) =>
          sendKeyEvent({
            eventCategory: 'Navigation',
            eventAction: 'Browse',
            eventLabel: 'Back Home',
          })
        }
      >
        <p>
          {' '}
          <FormattedMessage
            id={'category.backhome'}
            defaultMessage={'Back to Homepage'}
          />
        </p>
      </Link>
      <h1 className="categories-page-title">{title}</h1>
      {categoryProducts.length > 0 ? (
        <div className="category-products-div ">
          {' '}
          {categoryProducts.map((product) => (
            <CreateProductPreviewCard
              key={product._id}
              {...product}
            />
          ))}{' '}
        </div>
      ) : (
        <Loader />
      )}
      <Link
        to={getLocalizedPath('/')}
        className="back-to-home-link"
        style={{ '--color': color }}
        onClick={(e) =>
          sendKeyEvent({
            eventCategory: 'Navigation',
            eventAction: 'Browse',
            eventLabel: 'Back Home',
          })
        }
      >
        <p>
          {' '}
          <FormattedMessage
            id={'category.backhome'}
            defaultMessage={'Back to Homepage'}
          />
        </p>
      </Link>
      <GoToBasketLine />
    </div>
  );
}
