import { useContext, useState } from 'react';
import { API_ROUTES } from '../../data/constants';
import Loader from '../common/loading';
import { AuthContext } from '../../App';
import { FormattedMessage } from 'react-intl';

export default function DeleteAccount() {
  const [error, setError] = useState('');
  const { setIsLoggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const deleteAccount = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_ROUTES.DELETE_CLIENT}`, {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + token },
      });
      const data = await response.json();
      if (response.status === 200) {
        setIsLoading(false);
        handleLogout();
        setSuccess(true);
      } else {
        setIsLoading(false);
        setError(data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setError('Something terrible happened.');
      throw new Error(`Failed to delete account : ${error}`);
    }
  };

  return isLoading ? (
    <Loader />
  ) : success ? (
    <p>
      <FormattedMessage
        id="delete.success"
        defaultMessage="Your account was successfully deleted"
      />
    </p>
  ) : (
    <div className="modal-div">
      <p>
        <FormattedMessage
          id="delete.confirm"
          defaultMessage="You are about to delete your account, this is permanent."
        />
      </p>
      <button
        className="delete-account-btn"
        onClick={deleteAccount}
      >
        <FormattedMessage
          id="delete.button"
          defaultMessage="I know, delete the account"
        />
      </button>
      <p>{error}</p>
    </div>
  );
}
