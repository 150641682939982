export default function getAllValues(obj) {
  let values = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        values = values.concat(getAllValues(value));
      } else {
        values.push(value);
      }
    }
  }
  return values;
}
