import { FormattedMessage } from 'react-intl';
import BasketComponent from '../components/basketcomponents/generatebasketcomponent';
import React, { useContext } from 'react';
import { AuthContext } from '../App';
import LoginForm from '../components/login/logincomponent';
import { Link } from 'react-router-dom';

export default function BasketPage() {
  const { locale } = useContext(AuthContext);

  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <div className="content-div">
      <div className="basket-title-shopping-div">
        <h1>
          <FormattedMessage
            id="basketpage.title"
            defaultMessage="Your Basket"
          />
        </h1>
        <Link
          to={getLocalizedPath('/')}
          className="back-to-home-basket-link"
        >
          {' '}
          <FormattedMessage
            id="basketpage.link"
            defaultMessage="Continue shopping"
          />
        </Link>
      </div>
      <BasketComponent />
      <Link
        to={getLocalizedPath('/')}
        className="back-to-home-basket-link bottom mobile-only"
      >
        {' '}
        <FormattedMessage
          id="basketpage.link"
          defaultMessage="Continue shopping"
        />
      </Link>
    </div>
  );
}
