import React from 'react';
import { FormattedMessage } from 'react-intl';
import OrderItem from './orderitem'; // Assuming OrderItem is in the same directory
import Loader from '../common/loading';

function OrderListComponent({ orders, setOrders }) {
  const sortedOrders =
    orders.length > 0
      ? orders
          .filter((order) => order.orderPaid)
          .sort((a, b) => {
            // Convert dates to timestamps to compare
            return new Date(b.orderDate) - new Date(a.orderDate);
          })
      : [];

  return (
    <div className="ordersmanagement">
      <div className="ordersmanagement-div">
        <h1>
          <FormattedMessage
            id="orderlist.title"
            defaultMessage="Your Orders"
          />
        </h1>
      </div>
      {sortedOrders.length > 0 ? (
        sortedOrders.map((order) => (
          <OrderItem
            key={order._id}
            order={order}
            setOrders={setOrders}
          />
        ))
      ) : (
        <div className="ordersmanagement-div">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default OrderListComponent;
