import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../App';
import getOrderStatusMessage from './getorderstatusmessage';
import Modal from '../common/modale';
import ContestForm from './contestform';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCheckDouble,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

export default function OrderItem({ order, setOrders }) {
  const { products, socket } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [collapse, setCollapse] = useState(true);
  const messagesDivRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop = messagesDivRef.current.scrollHeight;
    }
  }, [chatOpen, message, messages]);

  useEffect(() => {
    setMessages(order.reclamation[0]?.messages || []);
  }, [order]);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim() === '') return; // Do not send empty messages

    if (socket) {
      socket.emit('send-message', {
        message,
        orderId: order._id,
      });

      setMessage(''); // Clear the input field after sending the message
    }
  };

  const handleInputFocus = () => {
    const unreadMessageIds = messages
      .filter((msg) => !msg.read && msg.adminmessage)
      .map((msg) => msg._id);

    if (socket && unreadMessageIds.length > 0) {
      socket.emit('mark-messages-read', {
        orderId: order._id,
        messageIds: unreadMessageIds,
      });
    }
  };

  return (
    <div className="order-div">
      <h3 className="order-title">
        <FormattedMessage
          id="orderitem.orderid"
          defaultMessage="Order:"
        />{' '}
        {order.orderNumber}{' '}
        <FormattedMessage
          id="orderitem.orderdate"
          defaultMessage="Order Date:"
        />{' '}
        <FormattedDate
          value={order.orderDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
        <button
          className="order-collapse-button"
          onClick={(e) => setCollapse(!collapse)}
        >
          {collapse ? '+' : '-'}
        </button>
      </h3>

      <div className={`order-status order-item-${collapse ? 'collapse' : ''}`}>
        <p>
          <FormattedMessage
            id="orderitem.status"
            defaultMessage="Status:"
          />{' '}
          {getOrderStatusMessage(order.orderShippingStatus)}
        </p>
        {order.orderShippingStatus !== 'awaiting_payment' &&
        order.orderShippingStatus !== 'order_to_prepare' &&
        order.orderShippingStatus !== 'refunded' &&
        order.orderShippingStatus !== 'delivered' ? (
          <p>
            <FormattedMessage
              id="orderitem.trackorder"
              defaultMessage="Track your order:"
            />{' '}
            {order.orderTracking}
          </p>
        ) : null}
      </div>

      <div
        className={`shipping-address order-item-${collapse ? 'collapse' : ''}`}
      >
        <h4>
          <FormattedMessage
            id="orderitem.shippingaddress"
            defaultMessage="Shipping Address:"
          />
        </h4>
        {order.orderShippingAddress.map((address) => (
          <p key={address._id}>
            {address.lineOne}, {address.city}, {address.postCode},{' '}
            {address.country}
          </p>
        ))}
      </div>

      <div
        className={`order-products order-item-${collapse ? 'collapse' : ''}`}
      >
        <h4>
          <FormattedMessage
            id="orderitem.products"
            defaultMessage="Products:"
          />
        </h4>
        <div className="order-products-container">
          <div className="order-products-header">
            <span>
              <FormattedMessage
                id="orderitem.productname"
                defaultMessage="Product Name"
              />
            </span>
            <span>
              <FormattedMessage
                id="orderitem.quantity"
                defaultMessage="Quantity"
              />
            </span>
            <span>
              <FormattedMessage
                id="orderitem.unitprice"
                defaultMessage="Unit Price"
              />
            </span>
            <span>
              <FormattedMessage
                id="orderitem.total"
                defaultMessage="Total"
              />
            </span>
          </div>
          <ul>
            {order.orderProducts.map((product) => {
              const productDetails = products.find(
                (p) => p._id === product.productId
              );
              return (
                <li
                  key={product.productId}
                  className="order-product-item"
                >
                  <span>
                    {productDetails
                      ? productDetails.title_fr
                      : 'Product not found'}
                  </span>
                  <span>{product.productQuantity}</span>
                  <span>€{product.productUnitPrice}</span>
                  <span>{product.productTotalPrice.toFixed(2)}€</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={`order-pricing order-item-${collapse ? 'collapse' : ''}`}>
        <p>
          <FormattedMessage
            id="orderitem.totalrevenue"
            defaultMessage="Total Price:"
          />{' '}
          {order.orderRevenue.toFixed(2)}€
        </p>
        <p>
          <FormattedMessage
            id="orderitem.shippingprice"
            defaultMessage="Shipping Price:"
          />{' '}
          {order.shippingPrice.toFixed(2)}€
        </p>{' '}
        <p>
          <FormattedMessage
            id="orderitem.totalpaid"
            defaultMessage="Total Paid:"
          />{' '}
          {(order.shippingPrice + order.orderRevenue).toFixed(2)}€
        </p>
        {order.order_promo ? <div>{order.order_promo}</div> : ''}
      </div>

      {order.contested ? (
        <div
          className={`order-contested order-item-${collapse ? 'collapse' : ''}`}
        >
          <p>
            <FormattedMessage
              id="orderitem.complaintDate"
              defaultMessage="Complaint Date: {date}"
              values={{
                date: (
                  <FormattedDate
                    value={new Date(order.reclamation[0].complaintDate)}
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="orderitem.complaintNumber"
              defaultMessage="Complaint Number: {number}"
              values={{ number: order.reclamation[0].complaintNumber }}
            />
          </p>

          <p className={`order-item-${collapse ? 'collapse' : ''}`}>
            <FormattedMessage
              id="orderitem.reason"
              defaultMessage="Reason: {reason}"
              values={{ reason: order.reclamation[0].reason }}
            />
          </p>
          <p className={`order-item-${collapse ? 'collapse' : ''}`}>
            <FormattedMessage
              id="orderitem.details"
              defaultMessage="Details: {details}"
              values={{ details: order.reclamation[0].details }}
            />
          </p>
        </div>
      ) : (
        <div>
          <button
            className="open-chat-button"
            onClick={() => setOpen(true)}
          >
            <FormattedMessage
              id="orderitem.contest"
              defaultMessage="Contest"
            />
          </button>{' '}
        </div>
      )}

      {chatOpen && (
        <div className="chat-container">
          <div className="chat-header">
            <h2>Live Chat</h2>
          </div>
          <div
            className="messages-div"
            ref={messagesDivRef}
          >
            {messages.length > 0 ? (
              messages.map((msg, index) => (
                <div
                  key={index}
                  className={`message-div ${
                    msg.adminmessage ? 'admin' : 'client'
                  }`}
                >
                  <span className="message-metadata">
                    {msg.sender}{' '}
                    <FormattedDate
                      value={order.orderDate}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </span>
                  <div className="message-content">
                    <p>{msg.message}</p>
                    <span className={`read-check ${msg.read ? 'read' : ''}`}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div>No messages</div>
            )}
          </div>
          <div className="input-div">
            <form
              className="send-message-form"
              onSubmit={handleSendMessage}
            >
              <input
                type="text"
                placeholder="Message ici"
                className="enter-message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onFocus={handleInputFocus}
              />
              <button
                type="submit"
                className="live-chat-submit-button"
              >
                {' '}
                <span className="desktop-only">
                  <FormattedMessage
                    id="orderitem.send"
                    defaultMessage="Send"
                  />
                </span>
                <span className="mobile-only">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
              </button>
            </form>
          </div>
        </div>
      )}

      {open && (
        <Modal
          isOpen={open}
          onClose={() => setOpen(false)}
        >
          <ContestForm
            orderId={order.orderNumber}
            setOrders={setOrders}
          />
        </Modal>
      )}
    </div>
  );
}
