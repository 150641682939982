import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../App';
import Loader from '../common/loading';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ContestForm({ orderId, setOrders }) {
  const [reason, setReason] = useState('');
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [complaintNumber, setComplaintNumber] = useState('');
  const [errorDetails, setErrorDetails] = useState('');
  const { socket } = useContext(AuthContext);
  const intl = useIntl();

  useEffect(() => {
    socket.on('complaint-noted', (response) => {
      try {
        if (response.success) {
          setComplaintNumber(response.complaintNumber);
          setOrders((prevOrders) =>
            prevOrders.map((o) =>
              o._id === response.order._id ? response.order : o
            )
          );
          setSuccess(true);
        } else {
          setErrorDetails(response.message);
          setFailure(true);
        }
        setLoading(false);
      } catch (error) {
        throw new Error(`Unable to send Complaint : ${error}`);
      }
    });
  }, [socket]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (socket) {
      setLoading(true);
      socket.emit('reclamation', {
        reason: reason,
        details: details,
        orderId: orderId,
      });
    }
    // console.log(`Order ID: ${orderId}, Reason: ${reason}, Details: ${details}`);
    // Submission logic here
  };

  if (loading) return <Loader />;

  return success ? (
    <div>
      <h2>
        <FormattedMessage
          id="contestform.success.title"
          defaultMessage="Your Complaint"
        />
      </h2>
      <p>
        <FormattedMessage
          id="contestform.success.message"
          defaultMessage="N°: {complaintNumber} has been noted"
          values={{ complaintNumber }}
        />
      </p>
      <p>
        <FormattedMessage
          id="contestform.success.contact"
          defaultMessage="Our services will contact you shortly."
        />
      </p>
    </div>
  ) : failure ? (
    <div>
      <h2>
        <FormattedMessage
          id="contestform.failure.title"
          defaultMessage="Complaint could not be posted."
        />
      </h2>
      <p>
        <FormattedMessage
          id="contestform.failure.error"
          defaultMessage="Error : {errorDetails}"
          values={{ errorDetails }}
        />
      </p>
      <p>
        <FormattedMessage
          id="contestform.failure.contact"
          defaultMessage="Please reach out at : reclamations@rivierabyata.com or by phone : 06 XX XX XX XX"
        />
      </p>
    </div>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="form-group"
    >
      <h3>
        <FormattedMessage
          id="contestform.title"
          defaultMessage="Contest Order Form"
        />
      </h3>
      <p>
        <FormattedMessage
          id="contestform.orderid"
          defaultMessage="Order ID:"
        />{' '}
        {orderId}
      </p>

      <label htmlFor="reason">
        <FormattedMessage
          id="contestform.reasonlabel"
          defaultMessage="Reason for Contesting:"
        />
      </label>
      <select
        id="reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        required
      >
        <option value="">
          <FormattedMessage
            id="contestform.choosereason"
            defaultMessage="--Please choose an option--"
          />
        </option>
        <option value="broken">
          <FormattedMessage
            id="contestform.reason.broken"
            defaultMessage="Broken Product"
          />
        </option>
        <option value="notDelivered">
          <FormattedMessage
            id="contestform.reason.notDelivered"
            defaultMessage="Not Delivered"
          />
        </option>
        <option value="wrongItem">
          <FormattedMessage
            id="contestform.reason.wrongItem"
            defaultMessage="Wrong Item Delivered"
          />
        </option>
        <option value="qualityIssue">
          <FormattedMessage
            id="contestform.reason.qualityIssue"
            defaultMessage="Quality Issue"
          />
        </option>
        <option value="other">
          <FormattedMessage
            id="contestform.reason.other"
            defaultMessage="Other"
          />
        </option>
      </select>

      {reason !== '' && (
        <React.Fragment>
          <label htmlFor="details">
            <FormattedMessage
              id="contestform.detailslabel"
              defaultMessage="Please specify:"
            />
            <span className="mandatoryStar">*</span>
          </label>
          <textarea
            id="details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            rows="10"
            required
          ></textarea>
        </React.Fragment>
      )}
      <button
        type="submit"
        className="form-submit-btn"
      >
        <FormattedMessage
          id="contestform.submitbutton"
          defaultMessage="Submit Contestation"
        />
      </button>
    </form>
  );
}
