import CgvComponent from '../components/cgvcomponents/cgvcomponent';
import ScrollToTop from '../components/common/scrolltotop';
import PrivacyComponent from '../components/privacycomponent/privacycomponent';

export default function PrivacyPage() {
  ScrollToTop();
  return (
    <div className="content-div">
      <PrivacyComponent />
    </div>
  );
}
