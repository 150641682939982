import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { API_ROUTES } from '../../data/constants';

export default function ContactFormComponent() {
  const intl = useIntl();
  const [success, setSuccess] = useState(false);

  const [failure, setFailure] = useState(false);
  const [formType, setFormType] = useState('professional'); // 'professional' or 'individual'
  const [formData, setFormData] = useState({
    companyName: '',
    siretNumber: '',
    businessSector: '',
    contactName: '',
    jobTitle: '',
    email: '',
    phone: '',
    companyAddress: '',
    productType: '',
    estimatedQuantity: '',
    specificRequest: '',
    howHeard: '',
    message: '',
    attachment: null,
    acceptTerms: false,
    occasion: '',
    address: '',
    formType: formType,
  });

  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
    setFormData({ ...formData, acceptTerms: false, formType: e.target.value }); // Reset acceptTerms when form type changes
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();

      // Append form data to FormData object
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          // If the key is 'attachment', append the file directly
          if (key === 'attachment') {
            data.append(key, formData[key]);
          } else {
            data.append(key, formData[key]);
          }
        }
      });

      const response = await fetch(`${API_ROUTES.CONTACT_US}`, {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        setSuccess(true);
        // Optionally reset the form
        setFormData({
          companyName: '',
          siretNumber: '',
          businessSector: '',
          contactName: '',
          jobTitle: '',
          email: '',
          phone: '',
          companyAddress: '',
          productType: '',
          estimatedQuantity: '',
          specificRequest: '',
          howHeard: '',
          message: '',
          attachment: null,
          acceptTerms: false,
          occasion: '',
          address: '',
          formType: formType,
        });
      } else {
        // Handle server errors

        const errorData = await response.json();

        setFailure(true);
        throw new Error('Server Error:', errorData);
      }
    } catch (error) {
      setFailure(true);
      throw new Error('Network Error:', error);
    }
  };

  return (
    <div className="contact-form">
      <h1 className="contact-form-title">
        <FormattedMessage
          id="contactForm.title"
          defaultMessage="Contact us"
        />
      </h1>
      {success ? (
        <div className="contact-form-success">
          <FormattedMessage
            id="contactForm.successMessage"
            defaultMessage="Thank you for contacting us! We will get back to you shortly."
          />
        </div>
      ) : failure ? (
        <div className="contact-form-failure">
          <FormattedMessage
            id="contactForm.failureMessage"
            defaultMessage="Oops! Something went wrong. Please try again later."
          />
        </div>
      ) : (
        <>
          <div className="contact-form-type-selection">
            <label className="contact-form-type-option">
              <input
                type="radio"
                name="formType"
                value="professional"
                checked={formType === 'professional'}
                onChange={handleFormTypeChange}
              />
              <FormattedMessage
                id="contactForm.professional"
                defaultMessage="Professional"
              />
            </label>
            <label className="contact-form-type-option">
              <input
                type="radio"
                name="formType"
                value="individual"
                checked={formType === 'individual'}
                onChange={handleFormTypeChange}
              />
              <FormattedMessage
                id="contactForm.individual"
                defaultMessage="Individual"
              />
            </label>
          </div>
          <form
            className="contact-form-fields"
            onSubmit={handleSubmit}
          >
            {/* Common Fields */}
            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.name"
                  defaultMessage="Name"
                />
              </label>
              <input
                type="text"
                name="contactName"
                value={formData.contactName}
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.email"
                  defaultMessage="Email Address"
                />
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.phone"
                  defaultMessage="Phone Number"
                />
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            {/* Form Type Specific Fields */}
            {formType === 'professional' && (
              <>
                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.companyName"
                      defaultMessage="Company Name"
                    />
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>

                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.siretNumber"
                      defaultMessage="SIRET Number (optional)"
                    />
                  </label>
                  <input
                    type="text"
                    name="siretNumber"
                    value={formData.siretNumber}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>

                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.businessSector"
                      defaultMessage="Business Sector"
                    />
                  </label>
                  <input
                    type="text"
                    name="businessSector"
                    value={formData.businessSector}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>

                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.jobTitle"
                      defaultMessage="Job Title"
                    />
                  </label>
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>

                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.companyAddress"
                      defaultMessage="Company Full Address"
                    />
                  </label>
                  <input
                    type="text"
                    name="companyAddress"
                    value={formData.companyAddress}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>
              </>
            )}

            {formType === 'individual' && (
              <div className="contact-form-field">
                <label className="contact-form-label">
                  <FormattedMessage
                    id="contactForm.address"
                    defaultMessage="Address"
                  />
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="contact-form-input"
                />
              </div>
            )}

            {/* Common Fields Continued */}
            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.productType"
                  defaultMessage="Type of Products or Services Sought"
                />
              </label>
              <input
                type="text"
                name="productType"
                value={formData.productType}
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            {formType === 'professional' && (
              <>
                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.estimatedQuantity"
                      defaultMessage="Estimated Volume or Quantity"
                    />
                  </label>
                  <input
                    type="text"
                    name="estimatedQuantity"
                    value={formData.estimatedQuantity}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>

                <div className="contact-form-field">
                  <label className="contact-form-label">
                    <FormattedMessage
                      id="contactForm.specificRequest"
                      defaultMessage="Specific Request"
                    />
                  </label>
                  <input
                    type="text"
                    name="specificRequest"
                    value={formData.specificRequest}
                    onChange={handleInputChange}
                    className="contact-form-input"
                  />
                </div>
              </>
            )}

            {formType === 'individual' && (
              <div className="contact-form-field">
                <label className="contact-form-label">
                  <FormattedMessage
                    id="contactForm.occasion"
                    defaultMessage="Occasion or Context"
                  />
                </label>
                <input
                  type="text"
                  name="occasion"
                  value={formData.occasion}
                  onChange={handleInputChange}
                  className="contact-form-input"
                />
              </div>
            )}

            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.howHeard"
                  defaultMessage="How Did You Hear About Us"
                />
              </label>
              <input
                type="text"
                name="howHeard"
                value={formData.howHeard}
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.message"
                  defaultMessage="Message"
                />
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="contact-form-textarea"
              ></textarea>
            </div>

            <div className="contact-form-field">
              <label className="contact-form-label">
                <FormattedMessage
                  id="contactForm.attachment"
                  defaultMessage="Attach Documents"
                />
              </label>
              <input
                type="file"
                name="attachment"
                onChange={handleInputChange}
                className="contact-form-input"
              />
            </div>

            <div className="contact-form-field contact-form-checkbox">
              <label className="contact-form-label">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  checked={formData.acceptTerms}
                  onChange={handleInputChange}
                  className="contact-form-checkbox-input"
                />
                <FormattedMessage
                  id="contactForm.acceptTerms"
                  defaultMessage="I accept the terms and conditions and the privacy policy"
                />
              </label>
            </div>

            <button
              type="submit"
              className="contact-form-submit"
            >
              <FormattedMessage
                id="contactForm.submit"
                defaultMessage="Submit"
              />
            </button>
          </form>{' '}
        </>
      )}
    </div>
  );
}
