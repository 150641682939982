import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import Loader from '../common/loading';
import { FILES_URL } from '../../data/constants';
import ScrollToTop from '../common/scrolltotop';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function CategoriesComponent() {
  const { products, categories, locale } = useContext(AuthContext);
  const [categoryItems, setCategoryItems] = useState([]);
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  const getCategoriesWithProducts = () => {
    const filteredCat = categories.filter((category) =>
      products.some((product) => product.category === category._id)
    );
    setCategoryItems(filteredCat);
  };

  useEffect(() => {
    if (products.length > 0) {
      getCategoriesWithProducts();
    }
  }, [products, categories]);

  function generateCategoryCard(category, i, locale) {
    const title = category[`title_${locale}`];
    const imageUrl = category.images[0] ? category.images[0].image : 'notfound';
    const image = `${FILES_URL}/${imageUrl}`;
    const color = category.color || '#00FF00'; // Default neon green
    const id = category._id;

    return (
      <Link
        to={getLocalizedPath(`/category/${id}`)}
        className="category-clickcard"
        key={title}
        onClick={(e) =>
          sendKeyEvent({
            eventCategory: 'Navigation',
            eventAction: 'Browse',
            eventLabel: 'View Category',
            eventValue: title,
          })
        }
      >
        <img
          src={image}
          alt={`${title}`}
          className="category-clickcard-image"
        />
        <h2 className="category-title">{title}</h2>
      </Link>
    );
  }
  ScrollToTop();
  return (
    <div className="categories-page-div">
      <h1 className="categories-page-title">
        <FormattedMessage
          id="categories.title"
          defaultMessage="Riviera By ATA"
        />
      </h1>
      <div className="categories-clickards-div">
        {categoryItems.length > 0 ? (
          categoryItems.map((category, i) =>
            generateCategoryCard(category, i, locale)
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
