import CategoriesComponent from '../components/categoriescomponent/categoriescomponent';
// import TeamComponent from '../components/teamcomponents/teamcomponent';

export default function CategoriesPage() {
  return (
    <div className="content-div">
      <CategoriesComponent />
    </div>
  );
}
