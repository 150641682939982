import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import DefaultSettingsComponent from './defaultsettingscomponent';
import ShippingAddressesComponent from './shippingaddressescomponent';
import OrdersManagementComponent from './ordersmanagement';
import SecurityManagementComponent from './securitymanagement';
import { FormattedMessage } from 'react-intl';
import { useParams, NavLink } from 'react-router-dom';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function AccountComponent() {
  const { user, locale } = useContext(AuthContext); // Access locale from context
  const { param } = useParams(); // Get the :param from the URL
  const [managementTab, setManagementTab] = useState('General'); // Default to 'General' if param is undefined

  useEffect(() => {
    if (param && param !== managementTab) {
      setManagementTab(param);
    }
  }, [param]);
  useEffect(() => {
    sendKeyEvent({
      eventCategory: 'Account',
      eventAction: 'View-Account',
      eventLabel: param,
    });
  }, []);

  const tabFunction = () => {
    switch (managementTab) {
      case 'General':
        return <DefaultSettingsComponent />;
      case 'Addresses':
        return <ShippingAddressesComponent />;
      case 'Orders':
        return <OrdersManagementComponent />;
      case 'Security':
        return <SecurityManagementComponent />;
      default:
        return <DefaultSettingsComponent />;
    }
  };

  // Helper function to generate localized paths
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <div className="accountManagementDiv">
      <div className="radio-inputs">
        <label
          className={managementTab === 'General' ? 'active radio' : 'radio'}
        >
          <NavLink to={getLocalizedPath('/account/General')}>
            <span className="name">
              <FormattedMessage
                id="account.general"
                defaultMessage="General"
              />
            </span>
          </NavLink>
        </label>
        <label
          className={managementTab === 'Addresses' ? 'active radio' : 'radio'}
        >
          <NavLink to={getLocalizedPath('/account/Addresses')}>
            <span className="name">
              <FormattedMessage
                id="account.addresses"
                defaultMessage="Addresses"
              />
            </span>
          </NavLink>
        </label>
        <label
          className={managementTab === 'Orders' ? 'active radio' : 'radio'}
        >
          <NavLink to={getLocalizedPath('/account/Orders')}>
            <span className="name">
              <FormattedMessage
                id="account.orders"
                defaultMessage="Orders"
              />
            </span>
          </NavLink>
        </label>
        <label
          className={managementTab === 'Security' ? 'active radio' : 'radio'}
        >
          <NavLink to={getLocalizedPath('/account/Security')}>
            <span className="name">
              <FormattedMessage
                id="account.security"
                defaultMessage="Security"
              />
            </span>
          </NavLink>
        </label>
      </div>
      <div className="accountManagementDetailsDiv">{tabFunction()}</div>
    </div>
  );
}
