import React, { useContext } from 'react';
import { AuthContext } from '../../App';
import { FILES_URL } from '../../data/constants';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

export default function ProducersGallery() {
  const { producers, locale } = useContext(AuthContext);

  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 6000,
  //   arrows: false,
  // };
  // const reverseProducers = [...producers].sort().reverse();

  return (
    <div className="producers-container">
      {/* <Slider {...settings}>
        {reverseProducers.map((producer) => {
          const descriptionArray = producer[`desc_${locale}`];

          return (
            <div
              className="slide "
              key={producer._id}
            >
              <img
                className="producer-slider-image"
                src={`${FILES_URL}/${
                  producer.images[0] ? producer.images[0].imgUrl : 'notfound'
                }`}
                alt={producer.title}
              />

              <div className="producer-slider-text-div">
                <h2>{producer.title}</h2>
                {descriptionArray.map((paragraph, index) =>
                  index < 1 ? (
                    <p
                      className="producer-slider-text"
                      key={index}
                    >
                      {paragraph.descParagraph}
                      {index === 0 ? (
                        <p>
                          {' '}
                          <Link
                            className="producer-slider-text"
                            to={`/producer/${producer._id}`}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faArrowRight} />
                            <FormattedMessage
                              id="producers.moreinfo"
                              defaultMessage=" More Info "
                            />
                          </Link>
                        </p>
                      ) : (
                        ''
                      )}
                    </p>
                  ) : (
                    ''
                  )
                )}
                <span></span>
              </div>
            </div>
          );
        })}
      </Slider> */}

      {producers.map((producer) => (
        <div
          className="producer-card"
          key={producer._id}
        >
          <Link to={getLocalizedPath(`/producer/${producer._id}`)}>
            {' '}
            <img
              src={`${FILES_URL}/${
                producer.images[0] ? producer.images[0].imgUrl : 'notfound'
              }`}
              alt={producer.title}
              className="producer-card-img"
            />
            <h4>{producer.title}</h4>
          </Link>{' '}
        </div>
      ))}
    </div>
  );
}
