import React from 'react';
import { FormattedMessage } from 'react-intl';

function GenerateAddresses({
  addressType,
  addresses,
  chosenAddress,
  setChosenAddress,
}) {
  const handleAddressSelection = (address) => {
    // console.log('Selected address:', address);
    if (chosenAddress && chosenAddress._id === address._id) {
      setChosenAddress({}); // Allow deselecting by re-clicking the same address
    } else {
      setChosenAddress(address); // Set new address as the chosen one
    }
  };

  return (
    <div className="generate-addresses-div">
      {addresses.length > 0 ? (
        addresses.map((address) => (
          <div
            key={address._id}
            className={`address-pick-div ${
              chosenAddress && chosenAddress._id === address._id
                ? 'selected'
                : ''
            }`}
            onClick={() => handleAddressSelection(address)}
          >
            <input
              type="radio"
              name="addressSelection"
              checked={chosenAddress && chosenAddress._id === address._id}
              onChange={() => handleAddressSelection(address)}
              className="pick-address-radio"
            />
            <div className="address-details-div">
              <p>
                <strong>
                  <FormattedMessage
                    id="generateaddresses.name"
                    defaultMessage="Name"
                  />
                  :
                </strong>{' '}
                {address.name ? (
                  address.name
                ) : (
                  <FormattedMessage
                    id="shippingaddresses.unnamed"
                    defaultMessage="Unnamed"
                  />
                )}
              </p>
              <p>
                <strong>
                  <FormattedMessage
                    id="generateaddresses.postalcode"
                    defaultMessage="Postal Code"
                  />
                  :
                </strong>{' '}
                {address.postCode}
              </p>
              <p>
                <strong>
                  <FormattedMessage
                    id="generateaddresses.city"
                    defaultMessage="City"
                  />
                  :
                </strong>{' '}
                {address.city}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="no-addresses-message">
          <FormattedMessage
            id="generateaddresses.noaddresses"
            defaultMessage="No addresses available."
          />
        </p>
      )}
    </div>
  );
}

export default GenerateAddresses;
