import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../App';
import getAllValues from '../common/getallvalues';
import CreateProductPreviewCard from '../productcardscomponent/productcardscomponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Link } from 'react-router-dom';
import GoToBasketLine from '../common/gotobasketcomponent';

export default function GalleryItems() {
  const intl = useIntl();
  const { products, categories, locale, confirmBasketProducts } =
    useContext(AuthContext);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const categoryRefs = useRef({});
  const filtersDivRef = useRef(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [basketExists, setBasketExists] = useState(false);

  const [basketQuantities, setBasketQuantities] = useState(0);
  useEffect(() => {
    const localProducts = JSON.parse(localStorage.getItem('products'));
    if (localProducts !== null && localProducts.length > 0) {
      const totalQuantity = localProducts.reduce(
        (acc, product) => acc + product.productQuantity,
        0
      );
      setBasketQuantities(totalQuantity);
      setBasketExists(true);
    } else {
      setBasketQuantities(0);
      setBasketExists(false);
    }
  }, [confirmBasketProducts]);

  function sortProducts(e) {
    const sortType = e ? e.target.value : 'prix-croissant';
    let sortedResult = [];

    switch (sortType) {
      case 'prix-decroissant':
        sortedResult = [...products].sort((a, b) => b.price - a.price);
        break;
      case 'prix-croissant':
        sortedResult = [...products].sort((a, b) => a.price - b.price);
        break;
      default:
        break;
    }

    setSortedProducts(sortedResult);
  }

  // Function to get categories that have products
  const getCategoriesWithProducts = () => {
    const filteredCat = categories.filter((category) =>
      products.some((product) => product.category === category._id)
    );
    setCategoryFilters(filteredCat);
  };

  useEffect(() => {
    if (products.length > 0) {
      sortProducts();
      getCategoriesWithProducts();
    }
  }, [products, categories]);

  useEffect(() => {
    // Filter products based on the search term only
    let tempFiltered = searchTerm
      ? sortedProducts.filter((product) =>
          getAllValues(product).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : sortedProducts;
    setFilteredProducts(tempFiltered);
  }, [searchTerm, sortedProducts]);

  // Scroll Event Listener
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const triggerPoint = viewportHeight * 0.3; // 30% from the top
      let active = null;

      categoryFilters.forEach((category) => {
        const element = categoryRefs.current[category._id];
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= triggerPoint && rect.bottom >= triggerPoint) {
            active = category._id;
          }
        }
      });

      if (active !== null && active !== activeCategory) {
        setActiveCategory(active);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initial call to set the active category
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [categoryFilters, activeCategory]);

  // Center active filter button
  useEffect(() => {
    if (activeCategory && filtersDivRef.current) {
      const activeButton = filtersDivRef.current.querySelector(
        `.category-filter-btn.active`
      );

      if (activeButton) {
        const filtersDivWidth = filtersDivRef.current.offsetWidth;
        const buttonOffsetLeft = activeButton.offsetLeft;
        const buttonWidth = activeButton.offsetWidth;
        const scrollPosition =
          buttonOffsetLeft - filtersDivWidth / 2 + buttonWidth / 2;

        filtersDivRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [activeCategory]);

  const createCategoryFilter = (category) => {
    const isActive = activeCategory === category._id;
    const color = category.color || '#00FF00'; // Default neon green
    return (
      <button
        id={`${category._id}`}
        key={`${category._id}`}
        className={`category-filter-btn ${isActive ? 'active' : ''}`}
        onClick={() => {
          if (categoryRefs.current[category._id]) {
            const element = categoryRefs.current[category._id];
            const headerOffset = 130; // Adjust this value to your header's height
            const elementPosition =
              element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }}
        style={{ '--color': color }}
      >
        {category[`title_${locale}`]}
      </button>
    );
  };

  const createCategoryDiv = (filteredProducts) => {
    return filteredProducts.length > 0 ? (
      categoryFilters.map((category) => {
        // Only use categories with products
        const categoryProducts = filteredProducts.filter(
          (product) => product.category === category._id
        );
        if (categoryProducts.length === 0) return null; // Skip categories with no products after filtering

        return (
          <div
            ref={(el) => (categoryRefs.current[category._id] = el)}
            className="category-div"
            key={category._id}
            data-category-id={category._id}
          >
            <h2 className="category-div-h2">{category[`title_${locale}`]}</h2>
            <div
              className={`category-products-div ${
                collapsedCategories[category._id] ? 'collapsed' : ''
              } `}
            >
              {categoryProducts.map((product) => (
                <CreateProductPreviewCard
                  key={product._id}
                  {...product}
                />
              ))}
            </div>
          </div>
        );
      })
    ) : (
      <FormattedMessage
        id="gallery.noproducts"
        defaultMessage="We couldn't find any product matching your query. Please review your search terms."
      />
    );
  };

  return (
    <React.Fragment>
      <div className="filtersDiv">
        <div
          className="filtersSubDiv"
          ref={filtersDivRef}
        >
          {categoryFilters.map((category) => createCategoryFilter(category))}
        </div>
      </div>
      <div className="galleryGlobalDiv">
        <div className="sortListDiv">
          <select
            className="sortList"
            onChange={sortProducts}
          >
            <option value="prix-croissant">
              <FormattedMessage
                id="gallery.sortab"
                defaultMessage="Lowest to Highest - Price"
              />
            </option>
            <option value="prix-decroissant">
              <FormattedMessage
                id="gallery.sortba"
                defaultMessage="Highest to Lowest - Price"
              />
            </option>
          </select>
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: 'gallery.filter',
              defaultMessage: 'Search Products',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="gallery-search-bar"
          />
        </div>
        <div className="galleryItems">
          {createCategoryDiv(filteredProducts)}
        </div>
      </div>
      <GoToBasketLine />
    </React.Fragment>
  );
}
