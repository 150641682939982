import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../App';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function RemoveProductBasket(productId, callback, socket) {
  // Fetch the existing products from localStorage and parse them into an array.
  const existingProducts = JSON.parse(localStorage.getItem('products')) || [];

  // Find the index of the product in the array (if it exists).
  const productIndex = existingProducts.findIndex(
    (product) => product.productId === productId
  );

  try {
    sendKeyEvent({
      eventCategory: 'E-Commerce',
      eventAction: 'Remove from Cart',
      eventLabel: productId,
    });
    if (productIndex !== -1) {
      // Product exists, decrement the quantity.
      existingProducts[productIndex].productQuantity -= 1;

      // If the quantity drops to 0, remove the product from the array
      if (existingProducts[productIndex].productQuantity <= 0) {
        existingProducts.splice(productIndex, 1);
      }
    } else {
      throw new Error(
        'Could not remove product (not found or minimum quantity)'
      );
    }
  } catch (error) {
    throw new Error('Error removing product from basket:', error);
  }

  // Save the updated products array back into localStorage.
  localStorage.setItem('products', JSON.stringify(existingProducts));

  if (socket) {
    const tempBasket =
      existingProducts.length === 0
        ? []
        : {
            tempBasketDate: new Date(), // Ajout de la date actuelle
            tempBasketProducts: existingProducts.map((product) => ({
              productId: product.productId,
              productQuantity: product.productQuantity,
            })),
          };

    socket.emit('update-basket', tempBasket);
  }
  // Execute the callback function if provided
  if (callback && typeof callback === 'function') {
    callback();
  }
}
