import React from 'react';
import ProducerComponent from '../components/producercomponent/producer';

export default function ProducerPage() {
  return (
    <div className="content-div">
      <ProducerComponent />
    </div>
  );
}
