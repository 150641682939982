import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../App';
import AddAddress from './addaddresscomponent';
import EditAddress from './editaddresscomponent';
import Loader from '../common/loading';
import { faL, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ShippingAddressesComponent() {
  const {
    shippingAddresses,
    setShippingAddresses,
    billingAddresses,
    setBillingAddresses,
    user,
    socket,
  } = useContext(AuthContext);
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();
  useEffect(() => {
    if (socket) {
      const handleAddressDeleted = (response) => {
        if (response.success) {
          console.log(
            'Address deleted successfully',
            response,
            response.shippingAddress
          );
          setShippingAddresses(response.shippingAddress || []);
          if (shippingAddresses.length <= 0) {
            setLoading(true);
          } // Ensure this is the array of all addresses
        } else {
          throw new Error(`Error deleting address: ${response.message}`);
        }
      };

      const handleAddressEdited = (response) => {
        if (response.success) {
          console.log('Address edited successfully', response.shippingAddress); // Corrected key if necessary
          setShippingAddresses(response.shippingAddress); // Ensure this is the array of all addresses
        } else {
          throw new Error(`Error editing address: ${response.message}`);
        }
      };

      socket.on('addressDeleted', (response) => handleAddressDeleted(response));
      socket.on('addressEdited', (response) => handleAddressEdited(response));

      // Cleanup function to remove both event listeners
      return () => {
        socket.off('addressDeleted', handleAddressDeleted);
        socket.off('addressEdited', handleAddressEdited);
      };
    }
  }, []); // Depend on socket and the setter from useState
  useEffect(() => {
    if (shippingAddresses.length > 0) {
      setLoading(false);
    } else if (!shippingAddresses || shippingAddresses.length <= 0) {
      setLoading(true);
    }
  }, [shippingAddresses]);
  const onDelete = (addressId) => {
    if (socket) {
      socket.emit('deleteAddress', { addressId });
    } else {
      throw new Error('Socket is not connected.');
    }
  };
  function handleEditAddresses() {
    setAddAddress(false);
    setEditAddress(!editAddress);
  }
  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    }
    return str;
  }

  function displayAddress(address, index) {
    if (address === undefined) {
      return <div>No address</div>;
    } else {
      // Using the truncateString function to limit the text displayed
      const name = truncateString(
        address.name ||
          intl.formatMessage({
            id: 'shippingaddresses.unnamed',
            defaultMessage: 'Unnamed',
          }),
        10
      );
      const lineOne = truncateString(address.lineOne, 8);
      const lineTwo = address.lineTwo ? truncateString(address.lineTwo, 8) : '';
      const postCode = truncateString(address.postCode, 5);
      const city = truncateString(address.city, 8);
      const country = truncateString(address.country, 8);

      return (
        <div
          className="display-address-div"
          key={index}
        >
          <h2>{name}</h2>
          <p>{lineOne}</p>
          <p>{postCode}</p>
          <p>{city}</p>
          <p>{country}</p>
          <button
            className="form-submit-btn delete-button"
            onClick={() => onDelete(address._id)}
          >
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </button>
        </div>
      );
    }
  }

  const handleEdit = (id, updatedAddress) => {
    if (socket) {
      socket.emit('editAddress', {
        clientId: user._id,
        addressId: id,
        shippingAddress: updatedAddress,
      });
      setEditAddress(false);
    } else {
      throw new Error('Socket is not connected.');
    }
  };

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
    setEditAddress(false);
    if (shippingAddresses.length === 0) {
      setLoading(!loading);
    } else {
      setLoading(false);
    }
  };

  // console.log('shipping:', shippingAddresses, 'billing : ', billingAddresses);
  return (
    <div className="form-container addressmanagement">
      {addAddress ? (
        <React.Fragment>
          <h1>
            <FormattedMessage
              id={'shippingaddresses.addaddresstitle'}
              defaultMessage={'Add an Address'}
            />
          </h1>
          <AddAddress setAddAddress={setAddAddress} />
        </React.Fragment>
      ) : editAddress ? (
        <React.Fragment>
          <h1>
            <FormattedMessage
              id={'shippingaddresses.editaddresstitle'}
              defaultMessage={'Add an Address'}
            />
          </h1>
          {shippingAddresses.length > 0 ? (
            shippingAddresses.map((address, index) => (
              <EditAddress
                key={address._id}
                address={address}
                onEdit={handleEdit}
              />
            ))
          ) : (
            <React.Fragment>
              <p>
                <FormattedMessage
                  id={'shippingaddresses.editnoaddressmessage'}
                  defaultMessage={'Start by adding an address.'}
                />
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          <h1>
            <FormattedMessage
              id={'shippingaddresses.addresstitle'}
              defaultMessage={'Add an Address'}
            />
          </h1>
          {loading ? (
            <div className="loader-div">
              <Loader />
            </div>
          ) : shippingAddresses ? (
            <div className="display-addresses">
              {shippingAddresses.map((address, index) =>
                displayAddress(address, index)
              )}
            </div>
          ) : (
            ''
          )}{' '}
        </React.Fragment>
      )}

      <div className="addressmanagement-btn-div">
        <button
          className="form-submit-btn addressmanagement"
          onClick={handleAddAddress}
        >
          {addAddress ? (
            <FormattedMessage
              id={'shippingaddresses.cancel'}
              defaultMessage={'Cancel'}
            />
          ) : (
            <FormattedMessage
              id={'shippingaddresses.addaddresstitle'}
              defaultMessage={'Add an Address'}
            />
          )}
        </button>
        <button
          className="form-submit-btn addressmanagement"
          onClick={handleEditAddresses}
        >
          {editAddress ? (
            <FormattedMessage
              id={'defaultsettings.cancel'}
              defaultMessage={'Cancel'}
            />
          ) : (
            <FormattedMessage
              id={'shippingaddresses.editaddresstitle'}
              defaultMessage={'Edit Addresses'}
            />
          )}
        </button>
      </div>
    </div>
  );
}
