import { useContext } from 'react';
import { AuthContext } from '../../App';

export default function estimateBasketMeasure() {
  const { products } = useContext(AuthContext);
  const basketProducts = JSON.parse(localStorage.getItem('products')) || [];

  let totalWeight = 0;
  let minHeight = 0;
  let minWidth = 0;
  let minDepth = 0;
  let totalVolume = 0;

  const calculatedItems = basketProducts
    .map((basketItem) => {
      const productDetails = products.find(
        (product) => product._id === basketItem.productId
      );
      if (productDetails && productDetails.dimensions.length > 0) {
        const { weight, height, width, depth } = productDetails.dimensions[0];

        // Accumulating total weight
        totalWeight += weight * basketItem.productQuantity;

        // Determining minimum dimensions required for storage
        minHeight = Math.max(minHeight, height);
        minWidth = Math.max(minWidth, width);
        minDepth = Math.max(minDepth, depth);

        // Calculating total volume
        totalVolume += height * width * depth * basketItem.productQuantity;

        return {
          ...basketItem,
          productWeight: weight,
          productHeight: height,
          productWidth: width,
          productDepth: depth,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  return {
    totalWeight,
    minHeight,
    minWidth,
    minDepth,
    totalVolume,
  };
}
