import React, { useState, useEffect, useContext, useRef } from 'react';
import { API_ROUTES } from '../../data/constants';
import { AuthContext } from '../../App';
import { Form, useNavigate } from 'react-router-dom';
import estimateBasketMeasure from './estimatebasketmeasure';
import { FormattedMessage } from 'react-intl';
import logoMondial from '../../assets/logo_mondial_relay.webp';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

// Fix for default marker icons not showing up in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

export default function ShippingComponent() {
  const mapContainerRef = useRef(null);
  const navigate = useNavigate();
  const {
    shipping,
    shippingId,
    setShippingId,
    basketTaxes,
    setBasketTaxes,
    chosenShippingAddress,
    chosenBillingAddress,
    setChosenBillingAddress,
    setChosenShippingAddress,
    locale,
    freeDelivery,
    freeDeliveryAmount,
  } = useContext(AuthContext);
  // console.log(chosenBillingAddress, chosenShippingAddress);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [relayPoints, setRelayPoints] = useState([]);
  const [selectedRelayPoint, setSelectedRelayPoint] = useState(null);
  const [noSelectedRelayMessage, setNoSelectedRelayMessage] = useState(false);
  const [freeDeliveryDifference, setFreeDeliveryDifference] = useState(0);
  const basketDimensions = estimateBasketMeasure();
  const handleSelectMethod = (method) => {
    setSelectedMethod(method);
    setShippingId(method._id);
  };

  const handleSelectRelayPoint = (relayPoint) => {
    setSelectedRelayPoint(relayPoint);
    setNoSelectedRelayMessage(false); // Clear the message when a relay point is selected
    // Add a delay of 1 second before proceeding to payment
  };

  useEffect(() => {
    if (!isEmptyObject(chosenShippingAddress)) {
      // console.log('chosenShipping address', chosenShippingAddress);
      fetchClosestRelayPoints(chosenShippingAddress);
    }
  }, [chosenShippingAddress]);

  useEffect(() => {
    if (selectedMethod) {
      if (mapContainerRef.current) {
        // Scroll to the map container
        mapContainerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (selectedMethod.relay && selectedRelayPoint) {
        setTimeout(() => {
          proceedToPayment();
        }, 1000); // 1000 milliseconds = 1 second
      } else if (selectedMethod && !selectedMethod.relay) {
        setTimeout(() => {
          proceedToPayment();
        }, 1000); // 1000 milliseconds = 1 second
      }
    } else return;
  }, [selectedRelayPoint, selectedMethod]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  const proceedToPayment = () => {
    if (!selectedMethod) return;

    const methodTax = selectedMethod.price * 0.2; // 20% tax rate
    setBasketTaxes(basketTaxes + methodTax);

    const relayDelivery = selectedMethod.relay;
    const billingEmpty = isEmptyObject(chosenBillingAddress);

    if (relayDelivery) {
      if (!selectedRelayPoint) {
        setNoSelectedRelayMessage(true);
        return;
      }

      const relayShippingAddress = {
        city: selectedRelayPoint.city,
        country: selectedRelayPoint.country,
        lineOne: `${selectedRelayPoint.house_number} ${selectedRelayPoint.street}`,
        lineTwo: selectedRelayPoint.name,
        postCode: selectedRelayPoint.postal_code,
      };

      if (billingEmpty) {
        setChosenBillingAddress(chosenShippingAddress);
      }

      setChosenShippingAddress(relayShippingAddress);
    }
    sendKeyEvent({
      eventCategory: 'E-Commerce',
      eventAction: 'Proceed to Checkout',
    });
    navigate(getLocalizedPath('/checkout'));
  };

  const fetchClosestRelayPoints = async (address) => {
    // const formattedAddress = `${address.lineOne}, ${address.city}, ${address.postCode}`;
    // const formattedAddress = { postcode: address.postCode, city: address.city };
    const country = address.country === 'France' ? 'FR' : address.country;

    const requestBody = {
      country: country,
      postcode: address.postCode,
      city: address.city,
      housenumber: address.lineOne,
      weight: basketDimensions.totalWeight,
    };

    try {
      const response = await fetch(`${API_ROUTES.RELAY_POINTS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      // console.log(data);
      setRelayPoints(data);
    } catch (error) {
      throw new Error('Error fetching relay points:', error);
    }
  };

  // Filter shipping methods based on the basket's dimensions and volume
  const availableShippingMethods = shipping.filter((method) => {
    const maxDimensions = method.dimensions_max[0]; // Assuming each method has at least one max dimensions entry
    const minDimensions = method.dimensions_min[0];

    return (
      maxDimensions.height >= basketDimensions.minHeight &&
      maxDimensions.width >= basketDimensions.minWidth &&
      maxDimensions.depth >= basketDimensions.minDepth &&
      maxDimensions.height * maxDimensions.width * maxDimensions.depth >=
        basketDimensions.totalVolume &&
      maxDimensions.weight >= basketDimensions.totalWeight &&
      minDimensions.height * minDimensions.width * minDimensions.depth <
        basketDimensions.totalVolume &&
      minDimensions.weight < basketDimensions.totalWeight
    );
  });

  const relayShippingMethods = availableShippingMethods.filter(
    (method) => method.relay
  );
  // console.log(relayShippingMethods);
  const homeShippingMethods = availableShippingMethods.filter(
    (method) => !method.relay
  );
  if (homeShippingMethods.length > 0 || relayShippingMethods.length > 0) {
    return (
      <div className="shipping-methods-div">
        {homeShippingMethods.length > 0 && (
          <div className="shipping-home-div">
            <h2>
              {' '}
              <FormattedMessage
                id="shipping.home"
                defaultMessage={'At Home Deliveries'}
              />
            </h2>
            {homeShippingMethods.map((method, index) => (
              <div
                key={index}
                onClick={() => handleSelectMethod(method)}
                style={{ cursor: 'pointer' }}
                className="shipping-method-div"
              >
                <h3>
                  {method.carrier} - {method[`title_${locale}`]}
                </h3>
                <p>
                  <FormattedMessage
                    id="shipping.delay"
                    defaultMessage="Delivery Date: "
                  />{' '}
                  <FormattedMessage
                    id={`shipping.delay.${method.delay}`}
                    defaultMessage={`${method.delay}`}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="shipping.price"
                    defaultMessage="Price:"
                  />{' '}
                  {freeDelivery ? (
                    <span>
                      {' '}
                      <span className="price-barred">{method.price} €</span> 0€
                    </span>
                  ) : (
                    <span>{method.price} €</span>
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
        {relayShippingMethods.length > 0 && (
          <div className="shipping-relay-div">
            <h2>
              <FormattedMessage
                id="shipping.relay"
                defaultMessage={'Relay Deliveries'}
              />
            </h2>

            {relayShippingMethods.map((method, index) => (
              <div
                key={index}
                onClick={() => handleSelectMethod(method)}
                style={{ cursor: 'pointer' }}
                className="shipping-method-div "
              >
                <div className="shipping-logo-title">
                  <img
                    src={logoMondial}
                    className="shipping-logo"
                  />
                  <h3 className="shipping-title">
                    {method.carrier} - {method[`title_${locale}`]}
                  </h3>
                </div>
                <p>
                  <FormattedMessage
                    id="shipping.delay"
                    defaultMessage="Delivery Date: "
                  />{' '}
                  <FormattedMessage
                    id={`shipping.delay.${method.delay}`}
                    defaultMessage="3 to 5 Business Days"
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="shipping.price"
                    defaultMessage="Price:"
                  />{' '}
                  {freeDelivery ? (
                    <span>
                      {' '}
                      <span className="price-barred">{method.price} €</span> 0€
                    </span>
                  ) : (
                    <span>{method.price}€</span>
                  )}
                </p>
              </div>
            ))}

            {relayPoints.length > 0 && (
              <div className="relay-points-div">
                {/* <div className="relay-points-pick-div">
                <h3>Closest Relay Points</h3>
                {relayPoints.map((relayPoint, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectRelayPoint(relayPoint)}
                    style={{ cursor: 'pointer' }}
                    className={`relay-point-div ${
                      selectedRelayPoint &&
                      selectedRelayPoint.id === relayPoint.id
                        ? 'selected'
                        : ''
                    }   shipping-method-div`}
                  >
                    <p>
                      {relayPoint.name} - {relayPoint.house_number}{' '}
                      {relayPoint.street}, {relayPoint.city}
                    </p>
                    <div className="open-hours">
                      <p>
                        <strong>Opening Hours:</strong>
                      </p>
                      {Object.entries(relayPoint.formatted_opening_times).map(
                        ([day, hours], dayIndex) => (
                          <p key={dayIndex}>
                            {
                              [
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                                'Sunday',
                              ][day]
                            }
                            : {hours.length > 0 ? hours.join(', ') : 'Closed'}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div> */}
                {selectedMethod && selectedMethod.relay ? (
                  <div
                    className="relay-points-map"
                    ref={mapContainerRef}
                  >
                    <MapContainer
                      center={[
                        parseFloat(relayPoints[0].latitude) || 48.8566,
                        parseFloat(relayPoints[0].longitude) || 2.3522,
                      ]}
                      zoom={13}
                      style={{
                        height: '500px',
                        width: '80vw',
                        maxWidth: '1300px',
                      }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {relayPoints.map((relayPoint, index) => (
                        <Marker
                          key={index}
                          position={[
                            parseFloat(relayPoint.latitude),
                            parseFloat(relayPoint.longitude),
                          ]}
                        >
                          <Popup>
                            <strong>{relayPoint.name}</strong>
                            <br />
                            {relayPoint.house_number} {relayPoint.street}
                            <br />
                            {relayPoint.city}
                            {Object.entries(
                              relayPoint.formatted_opening_times
                            ).map(([day, hours], dayIndex) => (
                              <p key={dayIndex}>
                                {
                                  [
                                    <FormattedMessage
                                      id="relay.monday"
                                      defaultMessage={'Monday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.tuesday"
                                      defaultMessage={'Tuesday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.wednesday"
                                      defaultMessage={'Wednesday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.thursday"
                                      defaultMessage={'Thursday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.friday"
                                      defaultMessage={'Friday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.saturday"
                                      defaultMessage={'Saturday'}
                                    />,
                                    <FormattedMessage
                                      id="relay.sunday"
                                      defaultMessage={'Sunday'}
                                    />,
                                  ][day]
                                }
                                :{' '}
                                {hours.length > 0 ? (
                                  hours.join(', ')
                                ) : (
                                  <FormattedMessage
                                    id="relay.closed"
                                    defaultMessage={'Closed'}
                                  />
                                )}
                              </p>
                            ))}
                            <div className="relay-choose-div">
                              <button
                                onClick={() =>
                                  handleSelectRelayPoint(relayPoint)
                                }
                                className="basket-submit-btn"
                              >
                                <FormattedMessage
                                  id="relay.select"
                                  defaultMessage={'Select'}
                                />
                              </button>
                              {selectedRelayPoint &&
                              selectedRelayPoint.name === relayPoint.name ? (
                                <span className="selected-relay-check">
                                  <FontAwesomeIcon icon={faCheck} />
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </Popup>
                        </Marker>
                      ))}
                    </MapContainer>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        )}
        {/* {selectedMethod && (
          <div className="chosen-method-div">
            <h4>
              <FormattedMessage
                id="shipping.selectedMethod"
                defaultMessage="Selected Shipping Method:"
              />
            </h4>
            <p>{`${selectedMethod.carrier} - ${
              selectedMethod[`title_${locale}`]
            } ${freeDelivery ? '0' : selectedMethod.price}€`}</p>
          </div>
        )}
        {selectedRelayPoint && (
          <div className="chosen-method-div">
            <h4>
              <FormattedMessage
                id="shipping.selectedrelay"
                defaultMessage={'Selected Relay Point'}
              />
            </h4>
            <p>{`${selectedRelayPoint.name} - ${selectedRelayPoint.house_number} ${selectedRelayPoint.street}, ${selectedRelayPoint.city}`}</p>
          </div>
        )}
        {noSelectedRelayMessage && (
          <p style={{ color: 'red' }}>
            <FormattedMessage
              id="shipping.norelayselected"
              defaultMessage="Please select a relay"
            />
          </p>
        )}
        {selectedMethod && (
          <button
            onClick={proceedToPayment}
            className="form-submit-btn basket-submit-btn"
          >
            <FormattedMessage
              id="shipping.proceed"
              defaultMessage="Proceed to Payment"
            />
          </button>
        )} */}
      </div>
    );
  } else
    return (
      <div className="shipping-methods-div">
        <FormattedMessage
          id="shipping.noMethods"
          defaultMessage="No shipping methods available that can accommodate your basket's dimensions and weight. Please reach out to our sales department: "
        />
        <a href="mailto:commandes@rivierabyata.com">
          commandes@rivierabyata.com
        </a>
      </div>
    );
}
