import ReactGA from 'react-ga4';

export default function sendKeyEvent(eventDetails) {
  // Parse the cookie preferences from localStorage
  let cookiePreferences;
  try {
    const preferences = localStorage.getItem('cookiePreferences');
    cookiePreferences = preferences ? JSON.parse(preferences) : {};
  } catch (error) {
    throw new Error('Failed to parse cookie preferences:', error);
    // return; // Stop execution if parsing fails
  }

  // Check if analytics cookies are allowed
  if (!cookiePreferences.analyticsCookies) {
    // console.warn('Analytics cookies are not allowed. Event not tracked.');
    return;
  }

  // Ensure ReactGA is initialized before sending events
  if (!ReactGA.isInitialized) {
    console.warn('ReactGA is not initialized. Event tracking aborted.');
    return;
  }

  try {
    // Validate and send the event details
    const { eventCategory, eventAction, eventLabel, eventValue } = eventDetails;

    if (!eventCategory || !eventAction) {
      throw new Error('Missing required event details: category or action.');
      return;
    }

    ReactGA.event({
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    });

    console.log('Event successfully tracked:', {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    });
  } catch (error) {
    throw new Error('Error tracking key event:', error);
  }
}
