import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import Loader from '../common/loading';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Modal from '../common/modale';
import DeleteAccount from './confirmaccountdeletioncomponent';

export default function SecurityManagementComponent() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { user, socket } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (newPassword !== confirmNewPassword) {
      setError(
        intl.formatMessage({
          id: 'securitymanagement.error.mismatch',
          defaultMessage: 'New passwords do not match.',
        })
      );
      setIsLoading(false);
      return;
    }
    setError(''); // Clear error on valid submission conditions

    // Call backend update function
    sendFormDataToBackend(currentPassword, newPassword);
  };
  const deleteAccountClicked = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };
  const sendFormDataToBackend = (currentPassword, newPassword) => {
    if (socket) {
      socket.emit('change-pwd', {
        clientId: user._id,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      setCurrentPassword('');
      setConfirmNewPassword('');
      setNewPassword('');
    } else {
      setError(
        intl.formatMessage({
          id: 'securitymanagement.error.connection',
          defaultMessage: 'Connection error, please try again later.',
        })
      );
      setIsLoading(false);
      throw new Error('Socket is not connected.');
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('updated-pwd', (response) => {
        const success = response.success;
        const message = response.message;
        if (!success) {
          setError(
            intl.formatMessage({
              id: 'securitymanagement.error.generic',
              defaultMessage: 'Something went wrong.',
            })
          );
          setIsLoading(false);
          return;
        }
        setSuccess(success);
        setIsLoading(false);
      });
    }
  }, [socket, intl]);

  return (
    <div className="form-container securitymanagement">
      <h1>
        <FormattedMessage
          id="securitymanagement.changepassword"
          defaultMessage="Change your Password"
        />
      </h1>
      {isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : success ? (
        <p>
          <FormattedMessage
            id="securitymanagement.success"
            defaultMessage="Password changed successfully"
          />
        </p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="form-group"
        >
          <div className="label-form-div">
            <label
              htmlFor="currentPassword"
              className="formLabel"
            >
              <FormattedMessage
                id="securitymanagement.currentpassword"
                defaultMessage="Current Password:"
              />
            </label>
            <input
              className="input"
              type={showPassword ? 'text' : 'password'}
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />{' '}
            <button
              type="button"
              onClick={toggleShowPassword}
              className="eye-button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="label-form-div">
            <label
              htmlFor="newPassword"
              className="formLabel"
            >
              <FormattedMessage
                id="securitymanagement.newpassword"
                defaultMessage="New Password:"
              />
            </label>
            <input
              className="input"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />{' '}
            <button
              type="button"
              onClick={toggleShowPassword}
              className="eye-button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          <div className="label-form-div">
            <label
              htmlFor="confirmNewPassword"
              className="formLabel"
            >
              <FormattedMessage
                id="securitymanagement.confirmnewpassword"
                defaultMessage="Confirm New Password:"
              />
            </label>
            <input
              className="input"
              type={showPassword ? 'text' : 'password'}
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />{' '}
            <button
              type="button"
              onClick={toggleShowPassword}
              className="eye-button"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button
            type="submit"
            className="form-submit-btn"
          >
            <FormattedMessage
              id="securitymanagement.submit"
              defaultMessage="Submit"
            />
          </button>
        </form>
      )}
      <button
        className="form-submit-btn"
        onClick={(e) => deleteAccountClicked(e)}
      >
        <FormattedMessage
          id="securitymanagement.deleteaccount"
          defaultMessage="Delete Account"
        />
      </button>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <DeleteAccount />
        </Modal>
      )}
    </div>
  );
}
