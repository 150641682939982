import { useState } from 'react';
import bannerImg from '../../assets/homebanner.webp';
import Modal from '../common/modale';
import PersonComponent from './personcomponent';
import { FormattedMessage } from 'react-intl';

export default function TeamComponent() {
  const [open, setOpen] = useState(false);
  const [person, setPerson] = useState('');
  const openModal = (e, person) => {
    e.preventDefault();
    setPerson(person);
    setOpen(true);
  };
  return (
    <div className="home-div">
      <img
        src={bannerImg}
        className="home-banner-img"
      />
      <div className="home-description">
        <h1 className="home-title">Riviera By ATA</h1>
        <p className="home-text">
          <FormattedMessage
            id="home.description1"
            defaultMessage="Born and raised in the heart of Provence, we are passionate about the treasures of our region and the authenticity of its products. As a family, we decided to create this online store to share with you local gems that we carefully select. Each product tells a story – one of artisans who, with passion and expertise, create wonders imbued with Provençal spirit. Our mission is simple: to offer a bit of Provence in every order, so you can enjoy and discover the beauty and authentic taste of our terroir from the comfort of your home. "
          />
        </p>
        <h1>
          <FormattedMessage
            id="home.family"
            defaultMessage="Meet the Family"
          />
        </h1>
        <div className="family-div">
          {' '}
          <span
            className="clickable-name"
            onClick={(e) => {
              openModal(e, 'anais');
            }}
          >
            Anaïs
          </span>
          {/* ,{' '} */}
          <span
            className="clickable-name"
            onClick={(e) => {
              openModal(e, 'thomas');
            }}
          >
            Thomas
          </span>{' '}
          {/* <FormattedMessage
            id="home.and"
            defaultMessage={' and '}
          /> */}
          <span
            className="clickable-name"
            onClick={(e) => {
              openModal(e, 'arthur');
            }}
          >
            Arthur
          </span>{' '}
        </div>
      </div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <PersonComponent individual={person} />
      </Modal>
    </div>
  );
}
