import React from 'react';

import { FormattedMessage } from 'react-intl';
import ForgotPassword from '../components/login/forgotpwdcomponent';

export default function ForgotPasswordPage() {
  return (
    <div className="content-div">
      <ForgotPassword />
    </div>
  );
}
