import {
  faAddressBook,
  faAddressCard,
  faBank,
  faShippingFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

export default function OrderStepsComponent({ stepNumber }) {
  const steps = [
    { id: 'address', icon: faAddressCard },
    { id: 'shipping', icon: faShippingFast },
    { id: 'checkout', icon: faBank },
  ];

  return (
    <div className="steps-container">
      {steps.map((step, index) => generateStep(step, index, stepNumber))}
    </div>
  );
}

const generateStep = (step, index, stepNumber) => {
  const isActive = index === stepNumber ? 'active' : '';
  const id = `steps.${step.id}`;
  const icon = step.icon;
  return (
    <div
      key={index}
      className={`checkout-step ${isActive}`}
    >
      <span className="step-icon">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className="step-text">
        <FormattedMessage id={id} />
      </span>
    </div>
  );
};
