import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import Loader from '../common/loading';
import { FormattedMessage, useIntl } from 'react-intl';

export default function DefaultSettingsComponent() {
  const { user, socket, setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [changePhone, setChangePhone] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [changeSurname, setChangeSurname] = useState(false);
  const [verifyEmailSent, setVerifyEmailSent] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const handleUserUpdate = (update) => {
      if (update.success) {
        setUser(update.user);
      } else {
        throw new Error(`Couldn't update account settings : ${update}`);
      }
    };

    if (socket) {
      socket.on('updated-user', handleUserUpdate);

      return () => {
        socket.off('updated-user', handleUserUpdate);
      };
    }
  }, [socket, setUser]);

  const toggleChange = (setter) => setter((state) => !state);

  const handleCheckbox = (event) => {
    socket.emit('update-user', {
      contactPreference: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedData = {};
    if (changeName) {
      updatedData.name = document.getElementById('name').value;
    }
    if (changePhone) {
      updatedData.phone = document.getElementById('phone').value;
      function validateFrenchPhoneNumber(phone) {
        const frenchPhoneRegex = /^(?:\+33|33|0)\s?[1-9](?:[ .-]?\d{2}){4}$/;
        return frenchPhoneRegex.test(phone);
      }

      if (!validateFrenchPhoneNumber(updatedData.phone)) {
        alert(
          intl.formatMessage({
            id: 'signupform.wrongphoneformat',
            defaultMessage: 'Please enter a valid Phone Number',
          })
        );
        return;
      }
    }
    if (changeSurname) {
      updatedData.surname = document.getElementById('surname').value;
    }
    if (socket && Object.keys(updatedData).length > 0) {
      socket.emit('update-user', updatedData);
    }
    console.log('Form submitted or change committed');
    setChangeName(false);
    setChangePhone(false);
    setChangeSurname(false);
  };

  if (loading) {
    return <Loader />;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div className="form-container accountmanagement">
      <form
        onSubmit={handleSubmit}
        className="form-group"
      >
        <div className="account-management-label-form-div">
          <h2 className="account-h2">
            <FormattedMessage
              id="defaultsettings.email"
              defaultMessage="Email"
            />
            :
          </h2>
          <p className="account-p">{`${
            user.email.length > 19 ? user.email.substring(0, 20) : user.email
          }...`}</p>
          {user.verifiedEmail ? (
            <span className="account-verified">
              <FormattedMessage
                id="defaultsettings.verified"
                defaultMessage="Verified"
              />
            </span>
          ) : verifyEmailSent ? (
            <span className="account-verified">
              <FormattedMessage
                id="defaultsettings.verificationemailsent"
                defaultMessage="Verification Email Sent"
              />
            </span>
          ) : (
            <button
              onClick={() => {
                if (socket) {
                  socket.emit('verify-email', { id: user._id });
                  setVerifyEmailSent(true);
                }
              }}
              className="form-submit-btn account-management"
            >
              <FormattedMessage
                id="defaultsettings.verifyemail"
                defaultMessage="Verify Email"
              />
            </button>
          )}
        </div>
        <div className="account-management-label-form-div label-form-div">
          {changePhone ? (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.phone"
                  defaultMessage="Phone"
                />
                :
              </h2>
              <input
                className="input-settings"
                type="tel"
                id="phone"
                defaultValue={user.phone}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                onClick={() => toggleChange(setChangePhone)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.cancel"
                  defaultMessage="Cancel"
                />
              </button>
            </>
          ) : (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.phone"
                  defaultMessage="Phone"
                />
                :
              </h2>
              <p className="account-p">{user.phone}</p>
              <button
                type="button"
                onClick={() => toggleChange(setChangePhone)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.changenumber"
                  defaultMessage="Change Number"
                />
              </button>
            </>
          )}
        </div>
        <div className="account-management-label-form-div">
          {changeName ? (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.name"
                  defaultMessage="Name"
                />
                :
              </h2>
              <input
                className="input-settings"
                type="text"
                id="name"
                defaultValue={user.name}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                onClick={() => toggleChange(setChangeName)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.cancel"
                  defaultMessage="Cancel"
                />
              </button>
            </>
          ) : (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.name"
                  defaultMessage="Name"
                />
                :
              </h2>
              <p className="account-p">{user.name}</p>
              <button
                type="button"
                onClick={() => toggleChange(setChangeName)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.changename"
                  defaultMessage="Change Name"
                />
              </button>
            </>
          )}
        </div>
        <div className="account-management-label-form-div">
          {changeSurname ? (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.surname"
                  defaultMessage="Surname"
                />
                :
              </h2>
              <input
                className="input-settings"
                type="text"
                id="surname"
                defaultValue={user.surname}
                onKeyDown={handleKeyDown}
              />
              <button
                type="button"
                onClick={() => toggleChange(setChangeSurname)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.cancel"
                  defaultMessage="Cancel"
                />
              </button>
            </>
          ) : (
            <>
              <h2 className="account-h2">
                <FormattedMessage
                  id="defaultsettings.surname"
                  defaultMessage="Surname"
                />
                :
              </h2>
              <p className="account-p">{user.surname}</p>
              <button
                type="button"
                onClick={() => toggleChange(setChangeSurname)}
                className="form-submit-btn account-management"
              >
                <FormattedMessage
                  id="defaultsettings.changesurname"
                  defaultMessage="Change Surname"
                />
              </button>
            </>
          )}
        </div>
        <div className="account-management-label-form-div">
          <h2 className="account-h2">
            <FormattedMessage
              id="defaultsettings.communications"
              defaultMessage="Marketing Communications"
            />
            :
          </h2>
          <input
            type="checkbox"
            defaultChecked={user.contactPreference}
            onChange={handleCheckbox}
          />
        </div>
        {changeName || changePhone || changeSurname ? (
          <button
            type="submit"
            className="form-submit-btn account-management"
          >
            <FormattedMessage
              id="defaultsettings.savechanges"
              defaultMessage="Save Changes"
            />
          </button>
        ) : (
          ''
        )}
      </form>
    </div>
  );
}
