export default function GetCurrentProductQty(productId) {
  try {
    const existingProducts = JSON.parse(localStorage.getItem('products')) || [];
    return existingProducts.reduce((quantities, product) => {
      quantities[product.productId] = product.productQuantity;
      // console.log(quantities);
      return quantities;
    }, {});
  } catch (error) {
    throw new Error(`Error getting products quantities ${error}`);
  }
}
