import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';

export default function LegalComponent() {
  const { locale } = useContext(AuthContext);

  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <div className="legal-container">
      <h1>
        <FormattedMessage
          id="legal.title"
          defaultMessage="Legal Notices"
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage
            id="legal.companyInfo.title"
            defaultMessage="Company Information"
          />
        </h2>
        <p>
          <FormattedMessage
            id="legal.companyName"
            defaultMessage="Company Name: Riviera By ATA SAS"
          />
          <br />
          <FormattedMessage
            id="legal.siret"
            defaultMessage="SIRET Number: 93208462700011"
          />
          <br />
          <FormattedMessage
            id="legal.rcs"
            defaultMessage="RCS Number: Versailles B 932 084 627"
          />
          <br />
          <FormattedMessage
            id="legal.vat"
            defaultMessage="VAT Number: FR70932084627"
          />
          <br />
          <FormattedMessage
            id="legal.shareCapital"
            defaultMessage="Share Capital: €1000"
          />
          <br />
          <FormattedMessage
            id="legal.address"
            defaultMessage="Address: 55 Avenue Hector Berlioz 78190 Trappes"
          />
          <br />
          <FormattedMessage
            id="legal.contactEmail"
            defaultMessage="Contact Email: "
          />
          <a href="mailto:contact@rivierabyata.com">contact@rivierabyata.com</a>
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage
            id="legal.hostingProvider.title"
            defaultMessage="Hosting Provider"
          />
        </h2>
        <p>
          <FormattedMessage
            id="legal.hostingProvider.name"
            defaultMessage="Name: OVH Cloud"
          />
          <br />
          <FormattedMessage
            id="legal.hostingProvider.corporateName"
            defaultMessage="Corporate Form: SASU"
          />
          <br />
          <FormattedMessage
            id="legal.hostingProvider.address"
            defaultMessage="Address: 2 rue Kellerman 59100 Roubaix"
          />
          <br />
          <FormattedMessage
            id="legal.hostingProvider.phone"
            defaultMessage="Phone Number: 1007 (from France)"
          />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage
            id="legal.terms.title"
            defaultMessage="General Sales Conditions"
          />
        </h2>
        <p>
          <FormattedMessage
            id="legal.terms.content"
            defaultMessage="You can find our general sales conditions "
          />
          <Link to={getLocalizedPath('/conditions')}>
            <FormattedMessage
              id="legal.terms.linkText"
              defaultMessage="here"
            />
          </Link>
          .
        </p>
      </section>
    </div>
  );
}
