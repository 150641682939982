import { useLocation } from 'react-router-dom';
import React, { useContext, useRef } from 'react';
import { AuthContext } from '../../App';
import { FILES_URL } from '../../data/constants';
import Slider from 'react-slick';
import { CustomNextArrow, CustomPrevArrow } from '../common/carouselarrows';

export default function Carousel({ productId }) {
  return (
    <div id="carouselPapa">
      <CreateCarousel productId={productId} />
    </div>
  );
}

function CreateCarousel({ productId }) {
  const { products } = useContext(AuthContext);
  const currentProductId = productId || useLocation().pathname.split('/')[2];
  const currentProduct = products.find(
    (product) => product._id === currentProductId
  );
  const imagesListLength = currentProduct?.images.length || 1;

  const sliderMainRef = useRef(null);

  const sliderSettingsMain = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,

    ref: sliderMainRef, // Assign ref to main slider
    arrows: false, // Disable default arrows for the thumbnail slider
  };

  return imagesListLength > 1 ? (
    <div className="product-carousel-container">
      <Slider {...sliderSettingsMain}>
        {currentProduct?.images.map((img, index) => (
          <div
            key={index}
            className="product-carousel"
          >
            <img
              src={`${FILES_URL}/${img.imgUrl || 'notfound'}`}
              alt={`${currentProduct.title_fr} image ${index + 1}`}
              className="product-carousel-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    <div className="product-carousel-container">
      {currentProduct?.images.map((img, index) => (
        <div
          key={index}
          className="product-carousel"
        >
          <img
            src={`${FILES_URL}/${img.imgUrl || 'notfound'}`}
            alt={`${currentProduct.title_fr} thumbnail ${index + 1}`}
            className="product-carousel-image"
          />
        </div>
      ))}
    </div>
  );
}
