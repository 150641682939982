import anaisImg from '../../assets/anais.webp';
import thomasImg from '../../assets/thomas.webp';
import arthurImg from '../../assets/arthur.webp';
import { FormattedMessage } from 'react-intl';

export default function PersonComponent({ individual }) {
  const images = {
    anais: anaisImg,
    thomas: thomasImg,
    arthur: arthurImg,
  };
  const image = images[individual];
  const names = {
    anais: 'Anaïs',
    thomas: 'Thomas',
    arthur: 'Arthur',
  };
  const name = names[individual];
  const formattedId = `person.${individual}`;
  //   console.log(formattedId);
  return (
    <div className="person-div">
      <img
        src={image}
        alt={individual}
        className="person-img"
      />
      <h1 className="person-title">{name}</h1>
      <p className="person-description">
        {' '}
        <FormattedMessage
          id={`${formattedId}.1`}
          defaultMessage={
            ''
          }
        />
      </p>
      <p className="person-description">
        {' '}
        <FormattedMessage
          id={`${formattedId}.2`}
          defaultMessage={
            ' '
          }
        />
      </p>
      <p className="person-description">
        {' '}
        <FormattedMessage
          id={`${formattedId}.3`}
          defaultMessage={
            ' '
          }
        />
      </p>
    </div>
  );
}
