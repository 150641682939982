import { FormattedMessage } from 'react-intl';

// import ShippingComponent from '../components/basketcomponents/shippingcomponent';
import VerifyEmailComponent from '../components/verifyemailcomponent/verifyemailcomponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function NotFoundPage() {
  return (
    <div className="content-div">
      <h1>
        {' '}
        <FormattedMessage
          id="notfoundpage.title"
          defaultMessage="404. Not Found."
        />
      </h1>
      <p>
        {' '}
        <FormattedMessage
          id="notfoundpage.content"
          defaultMessage="It seems you got lost in the perilous Calanques of Marseille !"
        />
      </p>
      <a href="/">
        <FontAwesomeIcon icon={faArrowRight} />
        <FormattedMessage
          id="notfoundpage.link"
          defaultMessage=" Return to Safety "
        />
      </a>
    </div>
  );
}
