import { useContext } from 'react';
import { AuthContext } from '../../App';
import { isNumber } from 'chart.js/helpers';
export default function checkQuantity(product) {
  const { products } = useContext(AuthContext);
  const linkedProducts = product.linkedProducts;
  // console.log(product);
  let quantity;
  linkedProducts.forEach((linkedProduct) => {
    // console.log(products, linkedProduct);
    const testedProduct = products.find((p) => p._id === linkedProduct.id);
    const linkedProductQty = linkedProduct.quantity;
    const testedProductStock = testedProduct.stock;
    const linkedProductStock = Math.floor(
      testedProductStock / linkedProductQty
    );
    if (!isNumber(quantity)) {
      quantity = linkedProductStock;
    } else if (quantity > linkedProductStock) {
      quantity = linkedProductStock;
    }
  });
  // console.log(quantity);
  return quantity;
}
