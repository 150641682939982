import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Socket } from 'socket.io-client';
import OrderListComponent from './orderlist';
import Loader from '../common/loading';
import logo from '../../assets/logo.webp';
export default function OrdersManagementComponent() {
  const { user, socket, orders, setOrders } = useContext(AuthContext);
  useEffect(() => {
    if (socket) {
      socket.emit('get-client-orders');
      socket.on('client-orders', (response) => {
        if (response.success) {
          setOrders(response.clientOrders);
        } else {
          throw new Error(`Error getting orders address: ${response.message}`);
        }
      });
    }
  }, [socket]);
  // console.log(socket);
  return orders && orders.length > 0 ? (
    <OrderListComponent
      orders={orders}
      setOrders={setOrders}
    />
  ) : (
    <Loader />
  );
}
