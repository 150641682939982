import { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { API_ROUTES } from '../../data/constants';
import { AuthContext } from '../../App';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

// import { trackPurchase } from '../common/analytics';
import { FormattedMessage } from 'react-intl';

export default function CheckoutComponent() {
  const [clientSecret, setClientSecret] = useState(null);
  const {
    user,
    socket,
    products,
    setProducts,
    shippingId,
    chosenShippingAddress,
    chosenBillingAddress,
    confirmBasketProducts,
    basketTaxes,
    promoCode = {},
    appliedPromoCode,
    setAppliedPromoCode,
    setPromoCode,
    setConfirmBasketProducts,
    locale,
    freeDelivery,
    certifiedLegal,
    alcohol,
    isLoggedIn,
  } = useContext(AuthContext);
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  const stripePromise = loadStripe(
    'pk_live_51PnDpWP14qk1RYA7wk8z7iBrV6adbIcNeqZNgwvaJRKNCIWcAjfQQHyAeif6tmQjc93bQBYnzXggBn4tsrLzrybp00yr32WdFd'
  );
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const navigate = useNavigate();
  const stripeObject = useMemo(() => stripePromise, []);
  // console.log(chosenShippingAddress);

  const fetchClientSecret = useCallback(() => {
    const orderData = appliedPromoCode
      ? {
          basketProducts: confirmBasketProducts,
          shipping: shippingId,
          order_tax: basketTaxes,
          shippingAddress: chosenShippingAddress,
          billingAddress: isEmptyObject(chosenBillingAddress)
            ? chosenShippingAddress
            : chosenBillingAddress,
          promoCode: promoCode.name,
          locale: locale,
          freeDelivery: freeDelivery,
          certified_legal: certifiedLegal,
          alcohol,
          user,
          isLoggedIn,
        }
      : {
          basketProducts: confirmBasketProducts,
          shipping: shippingId,
          order_tax: basketTaxes,
          shippingAddress: chosenShippingAddress,
          billingAddress: isEmptyObject(chosenBillingAddress)
            ? chosenShippingAddress
            : chosenBillingAddress,
          locale: locale,
          freeDelivery: freeDelivery,
          certified_legal: certifiedLegal,
          alcohol,
          user,
          isLoggedIn,
        };

    if (user.email !== null) {
      const userEmail = user.email;

      return fetch(`${API_ROUTES.CHECKOUT}/${userEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(orderData),
      }).then((res) => res.json().then((data) => data.clientSecret));
    } else {
      return fetch(`${API_ROUTES.CHECKOUT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(orderData),
      }).then((res) =>
        res.json().then((data) => console.log(data.clientSecret))
      );
    }
  }, []);
  const options = { fetchClientSecret };
  useEffect(() => {
    if (socket) {
      socket.on('order-update', (data) => {
        try {
          // Clear relevant localStorage items
          localStorage.removeItem('products');
          setAppliedPromoCode(false);
          setPromoCode({});

          // Fetch the updated product data
          FetchData(setProducts);
          setConfirmBasketProducts({});

          // Navigate to the provided message route
          navigate(`/${data.message}`);
        } catch (error) {
          throw new Error('Error handling order-update event:', error);
        }
      });

      // Cleanup listener on unmount
      return () => {
        socket.off('order-update');
      };
    }
  }, [socket]);

  return (
    <div className="checkout">
      <FormattedMessage
        id="checkout.proceedToPayment"
        defaultMessage="Proceed to Payment"
      />
      <div id="checkout">
        <EmbeddedCheckoutProvider
          stripe={stripeObject}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  );
}

async function FetchData(setProducts) {
  try {
    const [productRes] = await Promise.all([fetch(API_ROUTES.GET_PRODUCTS)]);
    const productsData = await productRes.json();
    setProducts(productsData);
  } catch (error) {
    // console.error('Error fetching data:', error);
    throw new Error(`Error fetching Data : ${error}`);
  }
}
