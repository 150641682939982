import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/logo.webp';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from '../common/modale';
import { API_ROUTES } from '../../data/constants';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../App';

export default function Footer() {
  const [open, setOpen] = useState(false);
  const { locale } = useContext(AuthContext); // Access locale from context

  // Helper function to generate localized paths
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <footer className="footer">
      {/* Uncomment the logo if needed */}
      {/* <img
        src={logo}
        alt="Logo de Riviera"
        className="logoFooter logo"
      /> */}
      <ul className="footer-list">
        <li>
          <Link to={getLocalizedPath('/legalnotice')}>
            <FormattedMessage
              id="footer.legal"
              defaultMessage="Legal Notices"
            />
          </Link>
        </li>
        <li>
          <Link to={getLocalizedPath('/contact')}>
            <FormattedMessage
              id="footer.contact"
              defaultMessage="Contact us!"
            />
          </Link>
        </li>
        <li
          onClick={() => setOpen(true)}
          className="link"
        >
          <FormattedMessage
            id="footer.bugreport"
            defaultMessage="Report a bug"
          />
        </li>
      </ul>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <BugReportForm />
      </Modal>
      <p className="footer-disclaimer">
        <FormattedMessage
          id="footer.psa"
          defaultMessage="Prohibition of the sale of alcoholic beverages to minors under the age of 18 - Public Health Code, Art. L.3342-1 and L.3353-3. Excessive alcohol consumption is dangerous to your health. Consume in moderation."
        />
      </p>
    </footer>
  );
}

const BugReportForm = () => {
  const reportUrl = API_ROUTES.POST_BUG_REPORT;
  const location = useLocation(); // Get the current location
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [severity, setSeverity] = useState('blocking');
  const [description, setDescription] = useState('');
  const [technicalData, setTechnicalData] = useState({});

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Collect technical data
    const getPlatform = () => {
      if (navigator.userAgentData && navigator.userAgentData.platform) {
        return navigator.userAgentData.platform;
      } else {
        return navigator.platform || 'Unknown';
      }
    };

    const data = {
      userAgent: navigator.userAgent,
      platform: getPlatform(),
      language: navigator.language,
      cookiesEnabled: navigator.cookieEnabled,
      screenSize: {
        width: window.screen.width,
        height: window.screen.height,
      },
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      currentPath: location.pathname, // Include the current path
    };
    setTechnicalData(data);
  }, [location.pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const reportData = {
      email: email || 'Anonymous',
      severity,
      description,
      technicalData,
    };

    try {
      const response = await fetch(reportUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
      });

      if (response.ok) {
        setSuccess(true);
        // Reset form fields if needed
        setEmail('');
        setSeverity('blocking');
        setDescription('');
      } else {
        const errorData = await response.json();

        setError(
          intl.formatMessage({
            id: 'bugReport.errorMessage',
            defaultMessage:
              'Failed to submit bug report. Please try again later.',
          })
        );
        throw new Error('Error submitting bug report:', errorData);
      }
    } catch (error) {
      setError(
        intl.formatMessage({
          id: 'bugReport.errorMessage',
          defaultMessage:
            'Failed to submit bug report. Please try again later.',
        })
      );
      throw new Error('Error submitting bug report:', error);
    }
  };

  return (
    <div className="bug-report-form">
      <h3>
        <FormattedMessage
          id="bugReport.title"
          defaultMessage="Report a Bug"
        />
      </h3>
      {success ? (
        <p>
          <FormattedMessage
            id="bugReport.successMessage"
            defaultMessage="Thank you for your bug report! We appreciate your feedback and will address the issue as soon as possible."
          />
        </p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="form-group"
        >
          <label htmlFor="email">
            <FormattedMessage
              id="bugReport.emailLabel"
              defaultMessage="Email Address (optional):"
            />
          </label>
          <input
            type="email"
            value={email}
            placeholder={intl.formatMessage({
              id: 'bugReport.emailPlaceholder',
              defaultMessage: 'Your email address',
            })}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="severity">
            <FormattedMessage
              id="bugReport.severityLabel"
              defaultMessage="Severity:"
            />
          </label>
          <select
            value={severity}
            onChange={(e) => setSeverity(e.target.value)}
          >
            <option value="blocking">
              <FormattedMessage
                id="bugReport.severity.blocking"
                defaultMessage="Blocking"
              />
            </option>
            <option value="bothering">
              <FormattedMessage
                id="bugReport.severity.bothering"
                defaultMessage="Bothering"
              />
            </option>
            <option value="visual">
              <FormattedMessage
                id="bugReport.severity.visual"
                defaultMessage="Just Visual"
              />
            </option>
          </select>
          <label htmlFor="description">
            <FormattedMessage
              id="bugReport.descriptionLabel"
              defaultMessage="Description of the Bug:"
            />
          </label>
          <textarea
            value={description}
            placeholder={intl.formatMessage({
              id: 'bugReport.descriptionPlaceholder',
              defaultMessage:
                "Please describe the issue you're experiencing...",
            })}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
          {error && <p className="error-message">{error}</p>}
          <button
            className="form-submit-btn"
            type="submit"
          >
            <FormattedMessage
              id="bugReport.submitButton"
              defaultMessage="Submit Bug Report"
            />
          </button>
        </form>
      )}
    </div>
  );
};
