import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import AddressComponent from '../components/basketcomponents/addresscomponent';
import { AuthContext } from '../App';
import LoginForm from '../components/login/logincomponent';
import CustomHelmet from '../components/helmet/helmet';
import { Link } from 'react-router-dom';
import OrderStepsComponent from '../components/ordersteps/orderstepscomponent';

export default function BillingAddressPage() {
  const { socket, user } = useContext(AuthContext);
  // console.log(user);
  return (
    <div className="content-div">
      <OrderStepsComponent stepNumber={0} />
      <h1>
        <FormattedMessage
          id="billingaddresses.title"
          defaultMessage="Pick or add a billing address"
        />
      </h1>
      <AddressComponent addressType="billing" />
    </div>
  );
}
