import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const CustomHelmet = ({
  title = 'Riviera By ATA',
  description = 'Le bon goût du Sud',
  url = 'https://www.rivierabyata.com',
  image = 'https://www.rivierabyata.com/logo-512.webp',
  type = 'website',
}) => {
  const location = useLocation(); // Get the current location object

  // Get the base URL dynamically using the window.location object
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const pageUrl = `${baseUrl}${location.pathname}`;

  return (
    <Helmet>
      {/* Title & Description */}
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />

      {/* Open Graph / Facebook */}
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:type"
        content={type}
      />
      <meta
        property="og:image"
        content={`${baseUrl}${image}`}
      />
      <meta
        property="og:url"
        content={pageUrl}
      />

      {/* Twitter */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />
      <meta
        name="twitter:image"
        content={`${baseUrl}${image}`}
      />

      {/* Canonical URL */}
      <link
        rel="canonical"
        href={pageUrl}
      />
    </Helmet>
  );
};

export default CustomHelmet;
