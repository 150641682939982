import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../App';
import GetCurrentProductQty from '../basketcomponents/getcurrentqtycomponent';
import AddProductBasket from '../basketcomponents/addbasketcomponent';
import RemoveProductBasket from '../basketcomponents/removebasketcomponent';
import { FILES_URL } from '../../data/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBasketShopping,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import getCurrentBasketProducts from '../basketcomponents/getcurrentbasketfunction';
import { FormattedMessage } from 'react-intl';
import Modal from '../common/modale';
import Carousel from '../productcomponents/productcarousel';
import ProductComponents from '../productcomponents/productcomponent';
import FlyingImagePortal from '../common/FlyingImagePortal';
import checkQuantity from '../common/checkstock';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function CreateProductPreviewCard(product) {
  const [quantity, setQuantity] = useState(0);
  const [open, setOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [flyStyle, setFlyStyle] = useState({});
  const imageRef = useRef(null);
  useEffect(() => {
    let timer;
    if (isAnimating) {
      timer = setTimeout(() => {
        setIsAnimating(false);
        setFlyStyle({});
      }, 1000); // Match this to your animation duration
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isAnimating]);

  const {
    locale,
    socket,
    categories,
    confirmBasketProducts,
    setConfirmBasketProducts,
    products,
    taxes,
    units,
    cartIconRef,
  } = useContext(AuthContext);
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  const languageNameKey = `title_${locale}`;
  const maxLength = 40; // Maximum allowed length including "..."
  const productName =
    product[languageNameKey].length > maxLength
      ? product[languageNameKey].slice(0, maxLength - 3) + '...'
      : product[languageNameKey];

  const languageUnitKey = `title_${locale}`;
  const productUnit =
    units.length > 0
      ? units.find((unit) => unit._id === product.unit)[languageUnitKey]
      : '';

  const productCategoryObject = categories.find(
    (cat) => cat._id === product.category
  );

  const productCategory = productCategoryObject
    ? productCategoryObject[languageNameKey]
    : 'Category Not Found';
  // console.log(product);
  const productUrl = product.images[0] ? product.images[0].imgUrl : 'notfound';
  const productImg = `${FILES_URL}/${productUrl}`;
  const productId = product._id;
  const productPrice = product.price;

  const productStock = !product.containsProducts
    ? product.stock
    : checkQuantity(product);
  const productStockClass =
    productStock <= 0
      ? 'productOutOfStock'
      : productStock < 10
      ? 'productLowStock'
      : 'productStockOk';

  const productVegan = product.vegan;
  const productGlutenFree = product.glutenfree;

  useEffect(() => {
    if (productId) {
      const quantityObject = GetCurrentProductQty();
      const quantityValue = quantityObject[productId] || 0;
      setQuantity(quantityValue);
    }
  }, [productId, confirmBasketProducts]);

  const updateProductQuantities = () => {
    if (productId) {
      const quantityObject = GetCurrentProductQty();
      const quantityValue = quantityObject[productId] || 0;
      setQuantity(quantityValue);
      const updatedQuantities = getCurrentBasketProducts(
        locale,
        products,
        taxes
      );

      setConfirmBasketProducts(updatedQuantities);
    }
  };
  const setModalOpen = (e) => {
    // console.log('click');
    e.preventDefault();
    sendKeyEvent({ eventCategory: 'Browse', eventAction: 'Modal Opened' });
    setOpen(true);
  };

  const handleAddToCart = () => {
    if (quantity === 0) {
      const imgRect = imageRef.current.getBoundingClientRect();
      const cartRect = cartIconRef.current.getBoundingClientRect();

      const deltaX =
        cartRect.left + cartRect.width / 2 - (imgRect.left + imgRect.width / 2);
      const deltaY =
        cartRect.top + cartRect.height / 2 - (imgRect.top + imgRect.height / 2);

      setFlyStyle({
        position: 'fixed',
        top: imgRect.top,
        left: imgRect.left,
        width: imgRect.width,
        height: imgRect.height,
        zIndex: 1000,
        transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
      });

      setIsAnimating(true);

      requestAnimationFrame(() => {
        setFlyStyle((prevStyle) => ({
          ...prevStyle,
          transform: `translate(${deltaX}px, ${deltaY}px) scale(0.1)`,
          opacity: 0,
        }));
      });

      // Add the product to the basket
      AddProductBasket(productId, updateProductQuantities, socket);

      // Reset the animation state after it finishes
      setTimeout(() => {
        setIsAnimating(false);
        setFlyStyle({});
      }, 1000); // Match this duration to your CSS transition
    } else {
      AddProductBasket(productId, updateProductQuantities, socket);
    }
  };
  return (
    <div
      className={`product-clickcard-global-div`}
      key={productId}
    >
      {quantity > 0 && (
        <Link
          to={getLocalizedPath('/basket')}
          className="basket-quantity-div"
        >
          <FontAwesomeIcon
            icon={faBasketShopping}
            className="basket-quantity-icon"
          />
          <p className="basket-quantity">{quantity}</p>
        </Link>
      )}
      {/* <Link to={`/product/${productId}`}> */}{' '}
      <div
        className="clickable-product-div"
        onClick={(e) => setModalOpen(e)}
      >
        <img
          className="product-clickcard-img"
          src={productImg}
          alt={productName}
          loading="lazy"
          ref={imageRef}
        />
        {/* </Link> */}
        <h3 className="clickCardTitle">{productName}</h3>
      </div>
      <span className="unit-span">
        {productPrice}€ / {productUnit}
      </span>
      <div className="products-tag-div">
        {productVegan && (
          <small className="badge">
            <FormattedMessage
              id="productpage.tags.vegan"
              defaultMessage="Vegan"
            />
          </small>
        )}
        {productGlutenFree && (
          <small className="badge">
            <FormattedMessage
              id="productpage.tags.glutenfree"
              defaultMessage="Gluten Free"
            />
          </small>
        )}
        {productStockClass === 'productLowStock' && (
          <small className="badge product-low-stock">
            {productStock}{' '}
            <FormattedMessage
              id="productclickcard.fewinstock"
              defaultMessage="in Stock!"
            />
          </small>
        )}
        {productStockClass === 'productOutOfStock' && (
          <small className="badge product-no-stock">
            <FormattedMessage
              id="productclickcard.outofstock"
              defaultMessage="Out of Stock"
            />
          </small>
        )}
      </div>
      {/* Add to Basket Section */}
      <div className="add-to-basket-section">
        <div className="add-to-basket-clickcard-div">
          {quantity > 0 ? (
            <div className="add-remove-clickcard-div">
              <button
                className="quantity-btn decrease product-add-remove-button"
                onClick={() =>
                  RemoveProductBasket(
                    productId,
                    updateProductQuantities,
                    socket
                  )
                }
              >
                <FontAwesomeIcon icon={quantity === 1 ? faTrash : faMinus} />
              </button>
              <span className="product-quantity-span">{quantity}</span>
              <button
                onClick={() =>
                  AddProductBasket(productId, updateProductQuantities, socket)
                }
                disabled={quantity >= productStock}
                className="product-add-remove-button increase"
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          ) : (
            <button
              onClick={() => handleAddToCart()}
              disabled={quantity >= productStock}
              className="product-add-to-basket-button"
            >
              <FormattedMessage
                id="productclickcard.add"
                defaultMessage={'Add to cart'}
              />{' '}
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <div className="product-parent-div ">
          <ProductComponents productId={productId} />
        </div>
      </Modal>
      {isAnimating && (
        <FlyingImagePortal>
          <img
            src={productImg}
            alt={productName}
            style={flyStyle}
            aria-hidden="true"
          />
        </FlyingImagePortal>
      )}
    </div>
  );
}
