import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../App';
import { FormattedMessage, useIntl } from 'react-intl';
import AddressAutoComplete from './addressautocomplete'; // Import the new component

export default function AddAddress({ onSave, setAddAddress }) {
  const intl = useIntl();
  const { socket, user, shippingAddresses, setShippingAddresses } =
    useContext(AuthContext);
  const [wrongAddress, setWrongAddress] = useState(false);
  const [wrongAddressMessage, setWrongAddressMessage] = useState('');
  const [wrongCountry, setWrongCountry] = useState(false);
  const [wrongZipCode, setWrongZipcode] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    lineOne: '',
    lineTwo: '',
    postCode: '',
    city: '',
    country: '',
  });

  useEffect(() => {
    if (socket) {
      socket.on('addressAdded', (response) => {
        if (response.success) {
          console.log('Address added successfully', response.shippingAddress);
          setShippingAddresses(response.shippingAddress);
          console.log(shippingAddresses);
          setAddAddress(false);
        } else {
          console.error('Error adding address:', response.message);
        }
      });

      return () => socket.off('addressAdded');
    }
  }, [socket, setShippingAddresses, setAddAddress]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAutoCompleteChange = (address) => {
    setFormData((prev) => ({ ...prev, ...address }));
  };
  const isCountryFrance = (country) => {
    const normalizedInput = country.trim().toLowerCase();
    if (normalizedInput === 'fr') {
      return {
        isExactMatch: true,
        isCloseEnough: true,
      };
    } else {
      const target = 'france';
      const distance = levenshteinDistance(normalizedInput, target);

      return {
        isCloseEnough: distance <= 2,
        isExactMatch: distance === 0,
      };
    }
  };
  const isValidFrenchPostcode = (postCode) => {
    return /^(0[1-9]\d{3}|[1-8]\d{4}|9[0-5]\d{3}|2[AB]\d{3})$/.test(postCode);
  };

  const levenshteinDistance = (a, b) => {
    const matrix = Array(b.length + 1)
      .fill(null)
      .map(() => Array(a.length + 1).fill(null));

    for (let i = 0; i <= a.length; i += 1) {
      matrix[0][i] = i;
    }
    for (let j = 0; j <= b.length; j += 1) {
      matrix[j][0] = j;
    }

    for (let j = 1; j <= b.length; j += 1) {
      for (let i = 1; i <= a.length; i += 1) {
        const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[j][i] = Math.min(
          matrix[j][i - 1] + 1, // deletion
          matrix[j - 1][i] + 1, // insertion
          matrix[j - 1][i - 1] + indicator // substitution
        );
      }
    }

    return matrix[b.length][a.length];
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { postCode, country } = formData;

    const { isCloseEnough, isExactMatch } = isCountryFrance(country);
    console.log(isCloseEnough, isExactMatch);
    if (!isCloseEnough || !isValidFrenchPostcode(postCode) || !country) {
      if (!isValidFrenchPostcode(postCode)) {
        setWrongZipcode(true);
      } else {
        setWrongZipcode(false);
      }
      if (!isCloseEnough) {
        setWrongCountry(true);
      } else {
        setWrongCountry(false);
      }
      setWrongAddress(true);
      setWrongAddressMessage(
        <span className="error-message">
          <FormattedMessage
            id="addresscomponent.onlyaddinfrance"
            defaultMessage="We only deliver in Mainland France and Corsica. Please reach out for specific orders: "
          />
        </span>
      );
      return;
    }
    if (socket) {
      socket.emit('addAddress', {
        clientId: user._id,
        shippingAddress: formData,
      });
      setFormData({
        name: '',
        lineOne: '',
        lineTwo: '',
        postCode: '',
        city: '',
        country: '',
      });
      setWrongCountry(false), setWrongZipcode(false), setWrongAddress(false);
    } else {
      throw new Error('Failed to add Address. No socket connected');
    }
  };

  return (
    <div className="form-container edit-address">
      <form
        className="form-group edit-address"
        onSubmit={handleSubmit}
      >
        <div className="label-form-div edit-address">
          <label
            htmlFor="name"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.name"
              defaultMessage="Name"
            />
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'addaddress.placeholder.name',
              defaultMessage: 'Name',
            })}
            autoComplete="name"
          />{' '}
        </div>
        <div className="label-form-div edit-address">
          {' '}
          <label
            htmlFor="lineOne"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.lineone"
              defaultMessage="Line One"
            />
          </label>
          <AddressAutoComplete
            value={formData}
            onChange={handleAutoCompleteChange}
          />
        </div>
        <div className="label-form-div edit-address">
          {' '}
          <label
            htmlFor="lineTwo"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.linetwo"
              defaultMessage="Line Two"
            />
          </label>
          <input
            type="text"
            name="lineTwo"
            value={formData.lineTwo}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'addaddress.placeholder.linetwo',
              defaultMessage: 'Building number, floor, flat...',
            })}
            autoComplete="address-line2"
          />
        </div>
        <div className="label-form-div edit-address">
          {' '}
          <label
            htmlFor="postCode"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.postcode"
              defaultMessage="Zipcode"
            />
          </label>
          <input
            type="text"
            name="postCode"
            value={formData.postCode}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'addaddress.placeholder.postcode',
              defaultMessage: 'ZipCode',
            })}
            autoComplete="postal-code"
            className={`${wrongZipCode ? 'error-input' : ''} `}
            required
          />{' '}
        </div>
        <div className="label-form-div edit-address">
          {' '}
          <label
            htmlFor="city"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.city"
              defaultMessage="City"
            />
          </label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'addaddress.placeholder.city',
              defaultMessage: 'City',
            })}
            autoComplete="address-level2"
            required
          />{' '}
        </div>
        <div className="label-form-div edit-address">
          {' '}
          <label
            htmlFor="country"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addaddress.country"
              defaultMessage="Country"
            />
          </label>
          <input
            type="text"
            name="country"
            className={`${wrongCountry ? 'error-input' : ''} `}
            value={formData.country}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'addaddress.placeholder.country',
              defaultMessage: 'Country',
            })}
            autoComplete="country"
            required
          />{' '}
        </div>
        {wrongAddress ? wrongAddressMessage : ''}
        <button
          type="submit"
          className="form-submit-btn edit-address"
        >
          <FormattedMessage
            id="addaddress.addbutton"
            defaultMessage="Add Address"
          />
        </button>
      </form>
    </div>
  );
}
