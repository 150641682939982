import CgvComponent from '../components/cgvcomponents/cgvcomponent';
import ScrollToTop from '../components/common/scrolltotop';

export default function CgvPage() {
  ScrollToTop();
  return (
    <div className="content-div">
      <CgvComponent />
    </div>
  );
}
