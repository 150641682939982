const localUrl = '/api';

export const SOCKET_ENDPOINT = 'https://www.rivierabyata.com';

export const API_ROUTES = {
  LOGIN: `${localUrl}/client/login`,
  ACCOUNT_CREATION: `${localUrl}/client/create-account`,
  LOGGED_USER: `${localUrl}/client/check`,
  GET_USER: `${localUrl}/client`,
  VERIFY_EMAIL: `${localUrl}/client/verify-email`,
  UPDATE_CLIENT: `${localUrl}/client/update-client`,
  DELETE_CLIENT: `${localUrl}/client/delete-client`,
  RESET_PWD: `${localUrl}/client/reset`,
  RELAY_POINTS: `${localUrl}/relays`,
  GET_PRODUCTS: `${localUrl}/product/published`,
  GET_CATEGORIES: `${localUrl}/category`,
  GET_PRODUCERS: `${localUrl}/producer`,
  GET_SHIPPING_METHODS: `${localUrl}/shipping-methods`,
  CHECKOUT: `${localUrl}/checkout`,
  CHECKOUT_SESSION_STATUS: `${localUrl}/checkout/session-status`,
  GET_TAXES: `${localUrl}/taxes`,
  GET_UNITS: `${localUrl}/units`,
  GET_FREE_DELIVERY: `${localUrl}/freedelivery`,
  POST_BUG_REPORT: `${localUrl}/bugreport`,
  GET_PROMO_CODE: `${localUrl}/promo/get-promo`,
  CONTACT_US:`${localUrl}/contact`,
};
export const FILES_URL = '/files';
