import { API_ROUTES } from '../../data/constants';

export default async function LoggedIn({
  setIsLoggedIn,
  setUser,
  setShippingAddresses,
  setBillingAddresses,
  setLocale,
  locale,
}) {
  // console.log('LoggedIn est appelée');
  const token = localStorage.getItem('token');

  async function fetchUserInfo() {
    try {
      const clientId = localStorage.getItem('clientId');
      const response = await fetch(`${API_ROUTES.GET_USER}/${clientId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (!response.ok) {
        localStorage.removeItem('token');
        localStorage.removeItem('clientId');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const clientInfo = await response.json();
      // console.log(clientInfo);
      setShippingAddresses(
        clientInfo.shippingAddress.length > 0 ? clientInfo.shippingAddress : []
      );
      setBillingAddresses(
        clientInfo.billingAddress.length > 0 ? clientInfo.billingAddress : []
      );
      setUser(clientInfo);
      if (clientInfo.locale && clientInfo.locale.length > 0) {
        setLocale(clientInfo.locale);
      }
      // console.log('récupération des données client', clientInfo);
      // console.log(user);
    } catch (error) {
      throw new Error(`Failed to fetch user info : ${error}`);
    }
  }

  if (token !== null) {
    // console.log('Token non null dans Localstorage');
    const formData = { token: token, locale: locale };

    const validToken = await fetch(`${API_ROUTES.LOGGED_USER}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(formData),
    });
    // console.log(validToken.status);

    if (validToken.status === 200 || validToken.status === 204) {
      try {
        await fetchUserInfo();
        setIsLoggedIn(true);
      } catch (error) {
        // console.error(error);
        throw new Error(`Failed to fetch user info : ${error}`);
      }
    } else if (validToken.status === 404 || validToken.status === 401) {
      // console.log(validToken.status, 'Token non validé !');
      setIsLoggedIn(false);
      setUser(null);

      setShippingAddresses([]);
      setBillingAddresses([]);
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      return;
    } else {
      setIsLoggedIn(false);
      setUser(null);
      setShippingAddresses([]);
      setBillingAddresses([]);
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      return;
    }
  }
}
