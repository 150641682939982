import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { AuthContext } from '../App';
import { redirect } from 'react-router';
import LoginForm from '../components/login/logincomponent';
import AccountComponent from '../components/accountmanagement/accountmanagementcomponent';
import CustomHelmet from '../components/helmet/helmet';
import ScrollToTop from '../components/common/scrolltotop';
export default function AccountPage() {
  const { user } = useContext(AuthContext);
  ScrollToTop();
  return (
    <div className="content-div">
      <CustomHelmet /> {user.email ? <AccountComponent /> : <LoginForm />}
    </div>
  );
}
