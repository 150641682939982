import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import GalleryItems from '../components/gallery/galleryitems';

import CustomHelmet from '../components/helmet/helmet';
import { AuthContext } from '../App';
import Loader from '../components/common/loading';
import ScrollToTop from '../components/common/scrolltotop';
export default function GalleryPage() {
  const { products } = useContext(AuthContext);
  ScrollToTop();
  return (
    <div className="content-div">
      <CustomHelmet
        url={'gallery'}
        type={'website'}
      />
      {/* <h1>
        <FormattedMessage
          id="gallery.h1"
          defaultMessage="Our Products"
        />
      </h1> */}
      {products.length > 0 ? <GalleryItems /> : <Loader />}
    </div>
  );
}
