import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../App';
import { useParams, Link } from 'react-router-dom';
import { FILES_URL } from '../../data/constants';
import Slider from 'react-slick';
import GetCurrentProductQty from '../basketcomponents/getcurrentqtycomponent';
import AddProductBasket from '../basketcomponents/addbasketcomponent';
import RemoveProductBasket from '../basketcomponents/removebasketcomponent';
import glutenFreeLogo from '../../assets/gluten-free-icon.png';
import veganLogo from '../../assets/veganlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import CreateProductPreviewCard from '../productcardscomponent/productcardscomponent';
import Loader from '../common/loading';
import { FormattedMessage } from 'react-intl';
import ScrollToTop from '../common/scrolltotop';
import GoToBasketLine from '../common/gotobasketcomponent';

export default function ProducerComponent({ producerId }) {
  const { producers, locale, products, categories, socket } =
    useContext(AuthContext);
  const [productQuantities, setProductQuantities] = useState({});
  const { id: paramsId } = useParams();

  const id = producerId || paramsId;

  useEffect(() => {
    const allQuantities = GetCurrentProductQty();
    setProductQuantities(allQuantities);
  }, [products]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentProducer, setCurrentProducer] = useState({});
  const [associatedProducts, setAssociatedProducts] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  useEffect(() => {
    const allQuantities = GetCurrentProductQty();
    setProductQuantities(allQuantities);
  }, [products]);

  useEffect(() => {
    if (producers && products) {
      const producer = producers.find((producer) => producer._id === id);
      if (producer) {
        setCurrentProducer(producer);
        // Filter products where the producer is part of the product's producers array
        const associated = products.filter((product) =>
          product.producers.some((p) => p.title === producer.title)
        );
        setAssociatedProducts(associated);
        setDescriptionArray(producer[`desc_${locale}`] || []);
        setIsLoading(false);
      } else {
        setCurrentProducer({});
        setAssociatedProducts([]);
        setDescriptionArray([]);
        setIsLoading(true);
      }
    }
  }, [producers, products, id, locale]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  if (!producerId) {
    ScrollToTop();
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="single-producer-div">
      {/* <h1>{currentProducer?.title}</h1> */}

      <div className="single-producer-slider">
        {currentProducer?.images.length > 1 ? (
          <Slider {...sliderSettings}>
            {currentProducer?.images.map((img, index) => (
              <div key={index}>
                <img
                  src={`${FILES_URL}/${img.imgUrl || 'notfound'}`}
                  alt={`${currentProducer.title} image ${index + 1}`}
                  className="producer-slider-image"
                />
              </div>
            ))}
          </Slider>
        ) : (
          currentProducer?.images.map((img, index) => (
            <div key={index}>
              <img
                src={`${FILES_URL}/${img.imgUrl || 'notfound'}`}
                alt={`${currentProducer.title} image ${index + 1}`}
                className="producer-slider-image"
              />
            </div>
          ))
        )}
      </div>
      <div className="producer-details-div">
        {descriptionArray.map((paragraph, index) =>
          paragraph.descParagraph.length < 100 ? (
            <h2
              className="producer-description-title"
              key={index}
            >
              {paragraph.descParagraph}
            </h2>
          ) : (
            <p
              className="producer-description-text"
              key={index}
            >
              {paragraph.descParagraph}
            </p>
          )
        )}
      </div>
      {/* Cards for Products */}
      {!producerId ? (
        <div className="producer-other-products-div">
          <h2>
            <FormattedMessage
              id="producerpage.productsby"
              defaultMessage={'Products by'}
            />{' '}
            {currentProducer?.title}
          </h2>
          <div className="producer-products-div">
            {associatedProducts.length > 0 ? (
              associatedProducts.map((product) => (
                <CreateProductPreviewCard
                  key={product._id}
                  {...product}
                />
              ))
            ) : (
              <p>
                <FormattedMessage
                  id="producerpage.noproductsfound"
                  defaultMessage={
                    'There are currently no products on sale by this Producer.'
                  }
                />{' '}
              </p>
            )}
          </div>
          <GoToBasketLine />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
