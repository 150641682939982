import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';

export default function CgvComponent() {
  const { locale } = useContext(AuthContext);
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  return (
    <div className="cgv-container">
      <h1>
        <FormattedMessage
          id="cgv.title"
          defaultMessage="Terms and Conditions"
        />
      </h1>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.userAccounts.title"
            defaultMessage="User Accounts"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.userAccounts.content"
            defaultMessage="To use certain features of our website, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for safeguarding your password and agree not to disclose it to any third party."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.privacy.title"
            defaultMessage="Privacy"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.privacy.content"
            defaultMessage="We are committed to protecting your privacy. Please review our Privacy Policy for details on how we collect, use, and protect your personal information."
          />{' '}
          <Link to={getLocalizedPath('/privacy')}>
            {' '}
            <FormattedMessage
              id="cgv.privacy.link"
              defaultMessage="Privacy"
            />
          </Link>
        </p>{' '}
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.payments.title"
            defaultMessage="Payments"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.payments.content"
            defaultMessage="All payments are processed securely. We accept various payment methods including credit/debit cards and PayPal. Your order will be processed once payment is received."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.shipping.title"
            defaultMessage="Shipping"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.shipping.content"
            defaultMessage="We offer shipping to various locations. Shipping charges and delivery times may vary depending on your location. You will receive a confirmation email with tracking details once your order is shipped."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.returns.title"
            defaultMessage="Returns"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.returns.content"
            defaultMessage="If you are not satisfied with your purchase, you may return the item within 30 days for a refund or exchange. Please ensure that the item is in its original condition and packaging. Return shipping costs are the responsibility of the customer."
          />
        </p>
      </section>
      <section>
        <h2>
          <FormattedMessage
            id="cgv.other.title"
            defaultMessage="Other Terms"
          />
        </h2>
        <p>
          <FormattedMessage
            id="cgv.other.content"
            defaultMessage="By using our website, you agree to comply with all applicable laws and regulations. We reserve the right to update these terms and conditions at any time. Please check this page regularly for updates."
          />
        </p>
      </section>
    </div>
  );
}
