import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

const Modal = ({ isOpen, onClose, children }) => {
  const [isClosing, setIsClosing] = useState(false);

  const closeModal = () => {
    sendKeyEvent({ eventCategory: 'Browse', eventAction: 'Modal Closed' });
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Duration matches the animation-duration
  };
  const modalRef = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div
        className={`modal-container ${isClosing ? 'closing' : 'active'}`}
        ref={modalRef}
      >
        <button
          className="modal-close-button"
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faX} />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};
export default Modal;
