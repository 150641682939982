import React from 'react';
import LoginForm from '../components/login/logincomponent';
import { FormattedMessage } from 'react-intl';
import ScrollToTop from '../components/common/scrolltotop';

export default function LoginPage() {
  ScrollToTop();
  return (
    <div className="content-div">
      {' '}
      <h1>
        <FormattedMessage
          id="loginpage.signintitle"
          defaultMessage="Sign In"
        />
      </h1>
      <LoginForm />
    </div>
  );
}
