import React, { useContext, useState } from 'react';
import { API_ROUTES } from '../../data/constants';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../App';
import { FormattedMessage, useIntl } from 'react-intl';
import LoggedIn from '../login/loggedin';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.fr';
export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setIsLoggedIn,
    setUser,
    setBillingAddresses,
    setShippingAddresses,
    locale,
    setLocale,
  } = useContext(AuthContext);
  const [loginChoice, setLoginChoice] = useState('login');
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleLogin = async (formData) => {
    try {
      const clientLoginAttempt = await fetch(`${API_ROUTES.LOGIN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(formData),
      });

      if (!clientLoginAttempt.ok) {
        const errorData = await clientLoginAttempt.json();
        setLoginError(true);
        setErrorMessage(errorData.error);
        throw new Error(`Failed to log client : ${errorData.error}`);
      }

      const data = await clientLoginAttempt.json();

      localStorage.setItem('clientId', data.clientId);
      localStorage.setItem('token', data.token);
      LoggedIn({
        setIsLoggedIn,
        setUser,
        setShippingAddresses,
        setBillingAddresses,
        setLocale,
        locale,
      });
      if (location.pathname.includes('/login')) {
        navigate('/');
      }
    } catch (error) {
      console.error('Login failed:', error.message);
      throw new Error(`Failed to log client : ${error.message}`); // Handle the login error (e.g., show an error message to the user)
    }
  };

  const handleSignin = async (formData, setSignupError) => {
    try {
      const clientSignInAttempt = await fetch(
        `${API_ROUTES.ACCOUNT_CREATION}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(formData),
        }
      );

      if (!clientSignInAttempt.ok) {
        const errorData = await clientSignInAttempt.json(); // Assuming the API responds with a JSON containing an error message
        setSignupError(errorData.message);
        throw new Error(errorData.message);
      }

      const data = await clientSignInAttempt.json();

      localStorage.setItem('clientId', data.clientId);
      localStorage.setItem('token', data.token);
      LoggedIn({
        setIsLoggedIn,
        setUser,
        setShippingAddresses,
        setBillingAddresses,
        setLocale,
        locale,
      });
      navigate('/signup-ok');
    } catch (error) {
      // console.error('Signin failed:', error.message);
      throw new Error(`Failed to create account: ${error.message}`); // Handle the signin error (e.g., show an error message to the user)
    }
  };

  return (
    <main className="login-main">
      <section className="sign-up-login-div">
        <div className="login-choice">
          <span
            onClick={() => setLoginChoice('login')}
            className={` login-choice-span ${
              loginChoice === 'login' ? 'active' : ''
            }`}
          >
            <FormattedMessage
              id="loginform.login"
              defaultMessage="Login"
            />
          </span>{' '}
          <span
            onClick={() => setLoginChoice('signup')}
            className={`login-choice-span  ${
              loginChoice === 'signup' ? 'active' : ''
            }`}
          >
            <FormattedMessage
              id="loginform.signup"
              defaultMessage="Sign Up"
            />
          </span>
        </div>
        {loginChoice === 'login' ? (
          <CreateLoginForm
            handleLogin={handleLogin}
            loginError={loginError}
            errorMessage={errorMessage}
          />
        ) : (
          <CreateSignupForm handleSignin={handleSignin} />
        )}
      </section>
    </main>
  );
}

function CreateLoginForm({ handleLogin, loginError, errorMessage }) {
  const [showPassword, setShowPassword] = useState(false);
  const { locale } = useContext(AuthContext);
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    // console.log(locale);
    const formData = { email: email, password: password, locale: locale };
    handleLogin(formData);
  };

  return (
    <div className="form-container loginForm">
      {' '}
      <form
        onSubmit={handleSubmit}
        className="form-group"
      >
        <h2>
          <FormattedMessage
            id="loginform.signinformh2"
            defaultMessage="Sign In"
          />
        </h2>
        <div className="label-form-div">
          <label
            htmlFor="email"
            className="formLabel"
          >
            <FormattedMessage
              id="loginform.email"
              defaultMessage="Email"
            />{' '}
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type="email"
            id="email"
            name="email"
            required={true}
          />
        </div>{' '}
        <div className="label-form-div">
          <label
            htmlFor="password"
            className="formLabel"
          >
            <FormattedMessage
              id="loginform.password"
              defaultMessage="Password"
            />{' '}
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            required={true}
          />{' '}
          <button
            type="button"
            onClick={toggleShowPassword}
            className="eye-button"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>{' '}
        {loginError ? <p>{errorMessage}</p> : ''}
        <div className="label-form-div">
          {' '}
          <button
            type="submit"
            className="form-submit-btn"
          >
            <FormattedMessage
              id="loginform.signinbutton"
              defaultMessage="Sign In"
            />
          </button>
          <Link to={'/forgot-pwd'}>
            <FormattedMessage
              id="loginform.forgotpassword"
              defaultMessage="Forgot Password?"
            />
          </Link>
        </div>
      </form>
    </div>
  );
}

function CreateSignupForm({ handleSignin }) {
  const intl = useIntl();
  const { locale } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [error, setError] = useState(false);
  const [signUpError, setSignupError] = useState('');

  const handlePasswordChange = (event) => {
    const newValue = event.target.value;
    setPassword(newValue);
    setError(
      newValue.length > 0 &&
        passwordCheck.length > 0 &&
        newValue !== passwordCheck
    );
  };

  const handlePasswordCheckChange = (event) => {
    const newValue = event.target.value;
    setPasswordCheck(newValue);
    setError(
      newValue.length > 0 && password.length > 0 && newValue !== password
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    function validateFrenchPhoneNumber(phone) {
      const frenchPhoneRegex = /^(?:\+33|33|0)\s?[1-9](?:[ .-]?\d{2}){4}$/;
      return frenchPhoneRegex.test(phone);
    }

    const phone = event.target.phone.value;
    if (!validateFrenchPhoneNumber(phone)) {
      setSignupError(
        intl.formatMessage({
          id: 'signupform.wrongphoneformat',
          defaultMessage: 'Please enter a valid Phone Number',
        })
      );

      return;
    }

    if (error) {
      setSignupError(
        intl.formatMessage({
          id: 'signupform.passwordmismatch',
          defaultMessage: 'Passwords must match',
        })
      );
      throw new Error(`Failed to Sign Up client : ${error}`);
      return;
    } else {
      const email = event.target.email.value;
      const password = event.target.password.value;
      const name = event.target.name.value;
      const surname = event.target.surname.value;
      const phone = event.target.phone.value;
      // const birthday = event.target.birthday.value;
      const approvedCGV = event.target.approvedCGV.checked;
      const contactAllowed = event.target.contactAllowed.checked;
      const contactPreference = event.target.contactPreference.checked;
      const formData = {
        email: email,
        password: password,
        name: name,
        surname: surname,
        phone: phone,
        approvedCGV: approvedCGV,
        contactAllowed: contactAllowed,
        contactPreference: contactPreference,
        locale: locale,
        // birthday: birthday,
      };
      handleSignin(formData, setSignupError);
    }
  };

  return (
    <div className="form-container loginForm">
      <form
        onSubmit={handleSubmit}
        className="form-group"
      >
        <span className="mandatoryStar">{signUpError}</span>
        <h2>
          {' '}
          <FormattedMessage
            id="signupform.signupformh2"
            defaultMessage="Sign Up"
          />
        </h2>
        <div className="label-form-div">
          {' '}
          <label
            htmlFor="name"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.firstname"
              defaultMessage="First Name"
            />
            <span className="mandatoryStar">*</span>
          </label>
          <input
            type="name"
            id="name"
            name="name"
            autoComplete="given-name"
            required={true}
            className="input"
          />
        </div>{' '}
        <div className="label-form-div">
          <label
            htmlFor="surname"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.lastname"
              defaultMessage="Last Name"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type="surname"
            id="surname"
            autoComplete="family-name"
            name="surname"
            required={true}
          />
        </div>{' '}
        <div className="label-form-div">
          <label
            htmlFor="email"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.email"
              defaultMessage="Email"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type="email"
            id="email"
            autoComplete="email"
            name="email"
            required={true}
          />
        </div>{' '}
        <div className="label-form-div">
          <label
            htmlFor="phone"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.phone"
              defaultMessage="Phone"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <Cleave
            className="input"
            type="tel"
            id="phone"
            name="phone"
            autoComplete="tel"
            required={true}
            options={{
              phone: true,
              phoneRegionCode: 'FR', // Adjust for your region
            }}
          />
        </div>
        {/* <div className="label-form-div">
          <label
            htmlFor="email"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.birthday"
              defaultMessage="Birthday"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type="date"
            id="birthday"
            autoComplete="bday"
            name="birthday"
            required={true}
          />
        </div>{' '} */}
        <div className="label-form-div">
          <label
            htmlFor="password"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.password"
              defaultMessage="Password"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            required={true}
          />{' '}
          <button
            type="button"
            onClick={toggleShowPassword}
            className="eye-button"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
          {error && (
            <span style={{ color: 'red' }}>
              <FormattedMessage
                id="signupform.passwordmismatch"
                defaultMessage="Passwords must match."
              />
            </span>
          )}
        </div>{' '}
        <div className="label-form-div">
          <label
            htmlFor="password-check"
            className="formLabel"
          >
            <FormattedMessage
              id="signupform.confirmpassword"
              defaultMessage="Confirm Password"
            />
            <span className="mandatoryStar">*</span>
          </label>{' '}
          <input
            className="input"
            type={showPassword ? 'text' : 'password'}
            id="password-check"
            name="password-check"
            value={passwordCheck}
            onChange={handlePasswordCheckChange}
            required={true}
          />
          {error && (
            <span style={{ color: 'red' }}>
              <FormattedMessage
                id="signupform.passwordmismatch"
                defaultMessage="Passwords must match."
              />
            </span>
          )}{' '}
          <button
            type="button"
            onClick={toggleShowPassword}
            className="eye-button"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>{' '}
        <div className="label-form-div-checkboxes">
          <div className="label-form-div checkbox">
            <input
              className="input-checkbox"
              type="checkbox"
              id="approvedCGV"
              name="approvedCGV"
              required={true}
            />{' '}
            <label htmlFor="approvedCGV">
              <FormattedMessage
                id="signupform.terms"
                defaultMessage="I have read and agree to the "
              />
              <Link to="/conditions">
                <FormattedMessage
                  id="signupform.termsofservice"
                  defaultMessage="Terms of Service"
                />
              </Link>{' '}
              <span className="mandatoryStar">*</span>
            </label>{' '}
          </div>{' '}
          <div className="label-form-div checkbox">
            <input
              className="input-checkbox"
              type="checkbox"
              id="contactPreference"
              name="contactPreference"
            />{' '}
            <label htmlFor="contactPreference">
              <FormattedMessage
                id="signupform.promotions"
                defaultMessage="I want to receive information about promotions, special offers, or new products from Riviera By ATA."
              />
            </label>
          </div>{' '}
          <div className="label-form-div checkbox">
            <input
              className="input-checkbox"
              type="checkbox"
              id="contactAllowed"
              name="contactAllowed"
              required={true}
            />{' '}
            <label htmlFor="contactAllowed">
              <FormattedMessage
                id="signupform.contactpermission"
                defaultMessage="I authorize Riviera By ATA to use my data to keep me informed about the status of my orders or to ask for more information to deliver the service."
              />
              <span className="mandatoryStar">*</span>
            </label>
          </div>
        </div>
        <div className="label-form-div">
          <button
            type="submit"
            className="form-submit-btn"
          >
            <FormattedMessage
              id="signupform.createaccountbutton"
              defaultMessage="Create Account"
            />
          </button>{' '}
          <p>
            <span className="mandatoryStar">*</span>{' '}
            <FormattedMessage
              id="signupform.mandatoryfields"
              defaultMessage="Mandatory fields."
            />
          </p>
        </div>
        <span className="mandatoryStar">{signUpError}</span>
      </form>
    </div>
  );
}
