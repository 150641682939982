import ScrollToTop from '../components/common/scrolltotop';
import ContactFormComponent from '../components/contactform/contactformcomponent';

export default function ContactPage() {
  ScrollToTop();
  return (
    <div className="content-div">
      <ContactFormComponent />
    </div>
  );
}
