import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef, useContext } from 'react';

import { AuthContext } from '../../App';
import { FILES_URL } from '../../data/constants';
import CustomHelmet from '../helmet/helmet';
import glutenFreeIcon from '../../assets/gluten-free-icon.png';
import veganIcon from '../../assets/veganlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CreateProductPreviewCard from '../productcardscomponent/productcardscomponent';
import AddProductBasket from '../basketcomponents/addbasketcomponent';
import RemoveProductBasket from '../basketcomponents/removebasketcomponent';
import GetCurrentProductQty from '../basketcomponents/getcurrentqtycomponent';
import getCurrentBasketProducts from '../basketcomponents/getcurrentbasketfunction';
import Loader from '../common/loading';
import Carousel from './productcarousel';
import { FormattedMessage } from 'react-intl';
import ScrollToTop from '../common/scrolltotop';
import ProducerComponent from '../producercomponent/producer';
import checkQuantity from '../common/checkstock';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';
export default function ProductComponents({ productId }) {
  return <ProductDescription productId={productId} />;
}

function ProductDescription({ productId }) {
  const { products, locale, units, producers } = useContext(AuthContext);
  const [categoryList, setCategoryList] = useState([]);
  const [producerLists, setProducerLists] = useState({});

  const location = useLocation();
  const currentProductId = productId || location.pathname.split('/')[2];
  const isBasket = location.pathname.split('/')[1] === 'basket';
  const currentProductObject = products.find(
    (product) => product._id === currentProductId
  );

  useEffect(() => {
    if (currentProductObject) {
      const filteredProducerLists = {};
      // console.log(currentProductObject);
      // Iterate over each producer in the current product's producers list
      currentProductObject.producers.forEach((producer) => {
        // Use the producer's id as the key to store the filtered list of products
        filteredProducerLists[producer.id] = products.filter(
          (p) =>
            p.producers.some((prod) => prod.id === producer.id) &&
            p._id !== currentProductId &&
            p.category !== currentProductObject.category
        );
      });

      // console.log(filteredProducerLists);
      // Filtrer les produits ayant la même catégorie
      const filteredCategoryList = products.filter(
        (p) =>
          p.category === currentProductObject.category &&
          p._id !== currentProductId
      );

      setCategoryList(filteredCategoryList);
      setProducerLists(filteredProducerLists);
    }
    sendKeyEvent({
      eventCategory: 'Product',
      eventAction: 'View Product',
      eventValue: currentProductObject.title_fr,
    });
  }, [currentProductObject, products, currentProductId]);

  if (!products || !currentProductObject) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <ProductDetails
      product={currentProductObject}
      categoryList={categoryList}
      locale={locale}
      units={units}
      producerLists={producerLists}
      producers={producers}
      isBasket={isBasket}
    />
  );
}

function ProductDetails({
  product,
  categoryList,
  locale,
  units,
  producerLists,
  producers,
  isBasket,
}) {
  const { products, socket, taxes, setConfirmBasketProducts } =
    useContext(AuthContext);
  const productId = product._id;
  const [quantity, setQuantity] = useState(0);
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  const productName = product[`title_${locale}`];
  const productDesc = product[`desc_${locale}`];
  const productPrice = product.price;
  const productUnit =
    units.length > 0
      ? units.find((unit) => product.unit === unit._id)[`title_${locale}`]
      : '';
  const productImg = product.images[0].imgUrl;
  const productStock = !product.containsProducts
    ? product.stock
    : checkQuantity(product);
  const productStockClass =
    productStock <= 0
      ? 'productOutOfStock'
      : productStock < 10
      ? 'productLowStock'
      : 'productStockOk';
  const glutenFree = product.glutenfree;
  const vegan = product.vegan;

  const productAllergens = product.allergens[0];
  const allergenList = [
    productAllergens.celery && 'celery',
    productAllergens.crustaceans && 'crustaceans',
    // ...other allergens...
    productAllergens.sulfites && 'sulfites',
  ].filter(Boolean);
  const [activeTab, setActiveTab] = useState(0);
  const tabHeadersRef = useRef(null);
  const tabRefs = useRef([]);

  useEffect(() => {
    // Scroll the active tab into the center of the tab headers container
    if (tabHeadersRef.current && tabRefs.current[activeTab]) {
      const tabHeadersWidth = tabHeadersRef.current.offsetWidth;
      const tabOffsetLeft = tabRefs.current[activeTab].offsetLeft;
      const tabWidth = tabRefs.current[activeTab].offsetWidth;

      const scrollPosition = tabOffsetLeft - tabHeadersWidth / 2 + tabWidth / 2;

      tabHeadersRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }, [activeTab]);
  const updateProductQuantities = () => {
    if (productId) {
      const quantityObject = GetCurrentProductQty();
      const quantityValue = quantityObject[productId] || 0;
      setQuantity(quantityValue);
      const updatedQuantities = getCurrentBasketProducts(
        locale,
        products,
        taxes
      );
      setConfirmBasketProducts(updatedQuantities);
    }
  };

  useEffect(() => {
    if (productId) {
      const quantityObject = GetCurrentProductQty();
      const quantityValue = quantityObject[productId] || 0;
      setQuantity(quantityValue);
    }
  }, [productId]);

  // Process productDesc into sections
  const sections = [];
  let currentSection = null;

  productDesc.forEach((paragraph, index) => {
    const text = paragraph.descParagraph;

    if (text.startsWith(' ')) {
      // List item
      if (!currentSection) {
        currentSection = { title: '', content: [] };
      }
      currentSection.content.push(
        <li
          key={index}
          className="product-detail-list"
        >
          {text.trim()}
        </li>
      );
    } else if (text.length < 20) {
      // Heading
      if (currentSection) {
        sections.push(currentSection);
      }
      currentSection = {
        title: text.trim(),
        content: [],
      };
    } else if (text.length < 100) {
      // List item
      if (!currentSection) {
        currentSection = { title: '', content: [] };
      }
      currentSection.content.push(
        <h3
          key={index}
          className="product-detail-title"
        >
          {text.trim()}
        </h3>
      );
    } else {
      // Paragraph
      if (!currentSection) {
        currentSection = { title: '', content: [] };
      }
      currentSection.content.push(
        <p
          key={index}
          className="product-detail-text"
        >
          {text.trim()}
        </p>
      );
    }
  });

  if (currentSection) {
    sections.push(currentSection);
  }

  // Create tabs array
  const tabs = sections.map((section) => ({
    title: section.title,
    content: (
      <div className="product-tab-details">
        {section.content.some((item) => item.type === 'li') ? (
          <ul className="product-details-list">{section.content}</ul>
        ) : (
          section.content
        )}
      </div>
    ),
  }));

  // Add producers as tabs
  product.producers.forEach((producer) => {
    tabs.push({
      title: producer.title,
      content: (
        <div>
          <ProducerComponent producerId={producer.id} />
          {/* Add more producer details if available */}
          {/* <Link to={`/producer/${producer.id}`}>
            <FormattedMessage
              id={'productpage.discoverproducer'}
              defaultMessage={'Find out more about {producer}'}
              values={{ producer: producer.title }}
            />
            <FontAwesomeIcon icon={faArrowRight} />
          </Link> */}
        </div>
      ),
    });
  });

  // Add allergens tab if applicable
  if (allergenList.length > 0) {
    tabs.push({
      title: (
        <FormattedMessage
          id={'productpage.allergenstitle'}
          defaultMessage={'Allergens'}
        />
      ),
      content: (
        <ul className="allergens-list">
          {allergenList.map((allergen, idx) => (
            <li
              key={idx}
              className="product-detail-list"
            >
              <FormattedMessage
                id={`productpage.allergen_${allergen}`}
                defaultMessage={allergen}
              />
            </li>
          ))}
        </ul>
      ),
    });
  }

  return (
    <>
      <CustomHelmet
        title={productName}
        description={productDesc[0].descParagraph}
        url={`https://www.yourwebsite.com/product/${product._id}`}
        image={`https://www.yourwebsite.com/files/${productImg}`}
        type="product"
      />
      <div className="product-main-div">
        <Carousel productId={productId} />

        <div className="product-info-div">
          <h1 className="product-name">{productName}</h1>
          <h2 className="product-price">
            <FormattedMessage
              id="productpage.unitprice"
              defaultMessage="{price}€ incl. VAT / {unit}"
              values={{ price: productPrice, unit: productUnit }}
            />
          </h2>
          <div className="products-tag-div">
            {vegan && (
              <small className="badge">
                <FormattedMessage
                  id={'productpage.tags.vegan'}
                  defaultMessage={'Vegan'}
                />
              </small>
            )}
            {glutenFree && (
              <small className="badge">
                <FormattedMessage
                  id={'productpage.tags.glutenfree'}
                  defaultMessage={'Gluten Free'}
                />
              </small>
            )}
            {productStockClass === 'productLowStock' && (
              <small className="badge product-low-stock">
                {productStock}{' '}
                <FormattedMessage
                  id={'productclickcard.fewinstock'}
                  defaultMessage={'in Stock!'}
                />
              </small>
            )}
            {productStockClass === 'productOutOfStock' && (
              <small className="badge product-no-stock">
                <FormattedMessage
                  id={'productclickcard.outofstock'}
                  defaultMessage={'Out of Stock'}
                />
              </small>
            )}
            {allergenList.map((allergen) => (
              <small
                className="badge"
                key={allergen}
              >
                <FormattedMessage
                  id={`productpage.allergen_${allergen}`}
                  defaultMessage={allergen}
                />
              </small>
            ))}
          </div>
          <div className="detail-div">
            <div className="add-to-basket-div">
              {quantity > 0 ? (
                <div className="add-remove-clickcard-div">
                  <button
                    className="quantity-btn decrease product-add-remove-button"
                    onClick={() =>
                      RemoveProductBasket(
                        productId,
                        updateProductQuantities,
                        socket
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={quantity === 1 ? faTrash : faMinus}
                    />
                  </button>
                  <span className="product-quantity-span">{quantity}</span>
                  <button
                    onClick={() =>
                      AddProductBasket(
                        productId,
                        updateProductQuantities,
                        socket
                      )
                    }
                    disabled={quantity >= productStock}
                    className="product-add-remove-button increase"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              ) : (
                <button
                  onClick={() =>
                    AddProductBasket(productId, updateProductQuantities, socket)
                  }
                  disabled={quantity >= productStock}
                  className="product-add-to-basket-button"
                >
                  <FormattedMessage
                    id="productclickcard.add"
                    defaultMessage={'Add to cart'}
                  />{' '}
                </button>
              )}
            </div>
            {quantity > 0 && !isBasket ? (
              <div className="">
                <Link
                  to={getLocalizedPath('/basket')}
                  className="product-go-to-basket-link"
                >
                  <FormattedMessage
                    id={'gallery.checkbasket'}
                    defaultMessage={'Go to Cart '}
                  />
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>

          {/* Render Tabs */}
          <div className="product-tabs">
            <div
              className="tab-headers"
              ref={tabHeadersRef}
            >
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`tab-header ${
                    activeTab === index ? 'active' : ''
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>

            <div className="tab-content">{tabs[activeTab].content}</div>
          </div>
        </div>
      </div>
      {/* <div className="more-products-div">
        {categoryList.length > 0 ? (
          <div className="similar-products">
            <h2>
              <FormattedMessage
                id={'productpage.similarproducts'}
                defaultMessage={'Similar Products'}
              />
            </h2>
            <div className="similar-products-click-card-div">
              {categoryList.map((product) => (
                <CreateProductPreviewCard
                  key={product._id}
                  {...product}
                />
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        {Object.entries(producerLists).map(
          ([producer, products]) =>
            products.length > 0 && (
              <div
                key={producer}
                className="same-producer"
              >
                <h2>
                  <FormattedMessage
                    id={'productpage.morefromproducer'}
                    defaultMessage={'More products from:'}
                  />{' '}
                  {producers.find((p) => p._id === producer)?.title ||
                    console.log(producers)}
                </h2>
                <div className="similar-products-click-card-div">
                  {products.map((product) => (
                    <CreateProductPreviewCard
                      key={product._id}
                      {...product}
                    />
                  ))}
                </div>
              </div>
            )
        )}
      </div> */}
    </>
  );
}
