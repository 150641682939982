import CgvComponent from '../components/cgvcomponents/cgvcomponent';
import ScrollToTop from '../components/common/scrolltotop';
import LegalComponent from '../components/legal/legalcomponent';

export default function LegalNoticePage() {
  ScrollToTop();
  return (
    <div className="content-div">
      <LegalComponent />
    </div>
  );
}
