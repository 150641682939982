// FlyingImagePortal.js
import React from 'react';
import ReactDOM from 'react-dom';

const FlyingImagePortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body // You can change this to another DOM node if needed
  );
};

export default FlyingImagePortal;
