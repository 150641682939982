import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

const AddressAutoComplete = ({ value, onChange }) => {
  const inputRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (!window.google) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ['address'],
        componentRestrictions: {
          country: [
            'FR',
            'AT',
            'BE',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',

            'DE',
            'GR',
            'HU',
            'IE',
            'IT',
            'LV',
            'LT',
            'LU',
            'MT',
            'NL',
            'PL',
            'PT',
            'RO',
            'SK',
            'SI',
            'ES',
            'SE',
          ],
        },
      }
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const address = place.address_components.reduce((acc, component) => {
          const type = component.types[0];
          switch (type) {
            case 'street_number':
              acc.lineOne =
                (component.long_name || '') + ' ' + (acc.lineOne || '');
              break;
            case 'route':
              acc.lineOne = (
                (acc.lineOne || '') +
                ' ' +
                component.long_name
              ).trim();
              break;
            case 'postal_code':
              acc.postCode = component.long_name || '';
              break;
            case 'locality':
              acc.city = component.long_name || '';
              break;
            case 'country':
              acc.country = component.long_name || '';
              break;
            default:
              break;
          }
          return acc;
        }, {});

        onChange(address);
      }
    });
  }, []);

  return (
    <input
      type="text"
      ref={inputRef}
      value={value.lineOne}
      onChange={(e) => onChange({ ...value, lineOne: e.target.value })}
      placeholder={intl.formatMessage({
        id: 'addaddress.placeholder.lineone',
        defaultMessage: 'Street and Number',
      })}
      autocomplete="address-line1"
      required
    />
  );
};

export default AddressAutoComplete;
