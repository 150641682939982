import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  awaitingCheckout: {
    id: 'orderStatus.awaitingCheckout',
    defaultMessage: 'Awaiting Checkout',
  },
  orderToPrepare: {
    id: 'orderStatus.orderToPrepare',
    defaultMessage: 'Preparing Your Order',
  },
  waitingForTransport: {
    id: 'orderStatus.waitingForTransport',
    defaultMessage: 'Waiting for Transport',
  },
  deliveryInProgress: {
    id: 'orderStatus.deliveryInProgress',
    defaultMessage: 'Delivery in Progress',
  },
  delivered: {
    id: 'orderStatus.delivered',
    defaultMessage: 'Delivered',
  },
  deliveryIssue: {
    id: 'orderStatus.deliveryIssue',
    defaultMessage: 'Delivery Issue',
  },
  refunded: {
    id: 'orderStatus.refunded',
    defaultMessage: 'Order Refunded',
  },
  statusUnknown: {
    id: 'orderStatus.statusUnknown',
    defaultMessage: 'Status Unknown',
  },
});

function getOrderStatusMessage(status) {
  switch (status) {
    case 'Awaiting_Checkout':
      return <FormattedMessage {...messages.awaitingCheckout} />;
    case 'order_to_prepare':
      return <FormattedMessage {...messages.orderToPrepare} />;
    case 'waiting_for_transport':
      return <FormattedMessage {...messages.waitingForTransport} />;
    case 'delivery_in_progress':
      return <FormattedMessage {...messages.deliveryInProgress} />;
    case 'delivered':
      return <FormattedMessage {...messages.delivered} />;
    case 'delivery_issue':
      return <FormattedMessage {...messages.deliveryIssue} />;
    case 'refunded':
      return <FormattedMessage {...messages.refunded} />;
    default:
      return <FormattedMessage {...messages.statusUnknown} />;
  }
}

export default getOrderStatusMessage;
