import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import GenerateAddresses from './generateaddressescomponent';
import { FormattedMessage, useIntl } from 'react-intl';
import AddAddress from '../accountmanagement/addaddresscomponent';
import ScrollToTop from '../common/scrolltotop';
import AddressAutoComplete from '../accountmanagement/addressautocomplete';
import Loader from '../common/loading';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function AddressComponent({ addressType }) {
  const type = addressType;
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    user,
    setUser,
    shippingAddresses,
    setShippingAddresses,
    billingAddresses,
    setBillingAddresses,
    chosenShippingAddress,
    setChosenShippingAddress,
    chosenBillingAddress,
    setChosenBillingAddress,
    isLoggedIn,
    setCertifiedLegal,
    locale,
  } = useContext(AuthContext);
  // Helper function to generate localized paths
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };
  // State for not logged-in users
  // console.log(user);
  const [guestShippingAddress, setGuestShippingAddress] = useState({
    lineOne: '',
    lineTwo: '',
    postCode: '',
    city: '',
    country: '',
  });
  const [guestBillingAddress, setGuestBillingAddress] = useState({
    lineOne: '',
    lineTwo: '',
    postCode: '',
    city: '',
    country: '',
  });
  const [useDifferentBillingAddress, setUseDifferentBillingAddress] =
    useState(false);

  const [addAddress, setAddAddress] = useState(false);
  const [wrongAddress, setWrongAddress] = useState(false);
  const [wrongAddressMessage, setWrongAddressMessage] = useState('');
  const [wrongCountry, setWrongCountry] = useState(false);
  const [wrongZipCode, setWrongZipcode] = useState(false);
  const chosenAddress =
    type === 'shipping' ? chosenShippingAddress : chosenBillingAddress;

  const setChosenAddress =
    type === 'shipping' ? setChosenShippingAddress : setChosenBillingAddress;

  const handleAddAddress = () => {
    setAddAddress(!addAddress);
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setChosenAddress((prev) => ({ ...prev, [name]: value }));
  };
  const handleUserChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setUser((prev) => ({ ...prev, [name]: value }));
    // console.log(user);
  };

  const handleAutoCompleteChange = (address) => {
    setChosenAddress((prev) => ({ ...prev, ...address }));
  };

  const handleSubmit = (e, sameAddress) => {
    e.preventDefault();
    sendKeyEvent({
      eventCategory: 'E-Commerce',
      eventAction: 'Validate Address',
    });
    if (!chosenAddress || Object.keys(chosenAddress).length === 0) {
      alert(
        intl.formatMessage({
          id: 'addresscomponent.pleaseselect',
          defaultMessage: 'Please select or enter a shipping address.',
        })
      );
      return;
    }
    // console.log('chosenAddress:', chosenAddress);
    const { postCode, country } = chosenAddress;
    const { isCloseEnough, isExactMatch } = isCountryFrance(country);
    // console.log(isCloseEnough, isExactMatch);
    if (!isCloseEnough || !isValidFrenchPostcode(postCode) || !country) {
      console.log(isCloseEnough, isValidFrenchPostcode(postCode), country);
      if (!isValidFrenchPostcode(postCode)) {
        setWrongZipcode(true);
      } else {
        setWrongZipcode(false);
      }
      if (!isCloseEnough) {
        setWrongCountry(true);
      } else {
        setWrongCountry(false);
      }
      setWrongAddress(true);
      setWrongAddressMessage(
        <span className="error-message">
          <FormattedMessage
            id="addresscomponent.onlydeliverfrance"
            defaultMessage="We only deliver in Mainland France and Corsica. Please reach out for specific orders: "
          />
          <a
            href="mailto:commandes@rivierabyata.com"
            className="error-message"
          >
            <FormattedMessage
              id="addresscomponent.email"
              defaultMessage="commandes@rivierabyata.com"
            />
          </a>
        </span>
      );
    } else {
      setWrongCountry(false), setWrongZipcode(false), setWrongAddress(false);

      // Logged-in user logic remains the same
      // console.log(chosenAddress);
      sameAddress
        ? navigate(getLocalizedPath('/shipping'))
        : navigate(getLocalizedPath('/billingaddress'));
    }
  };

  const isValidFrenchPostcode = (postCode) => {
    return /^(0[1-9]\d{3}|[1-8]\d{4}|9[0-5]\d{3}|2[AB]\d{3})$/.test(postCode);
  };

  const levenshteinDistance = (a, b) => {
    const matrix = Array(b.length + 1)
      .fill(null)
      .map(() => Array(a.length + 1).fill(null));

    for (let i = 0; i <= a.length; i += 1) {
      matrix[0][i] = i;
    }
    for (let j = 0; j <= b.length; j += 1) {
      matrix[j][0] = j;
    }

    for (let j = 1; j <= b.length; j += 1) {
      for (let i = 1; i <= a.length; i += 1) {
        const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[j][i] = Math.min(
          matrix[j][i - 1] + 1, // deletion
          matrix[j - 1][i] + 1, // insertion
          matrix[j - 1][i - 1] + indicator // substitution
        );
      }
    }

    return matrix[b.length][a.length];
  };

  const isCountryFrance = (country) => {
    const normalizedInput = country.trim().toLowerCase();
    if (normalizedInput === 'fr') {
      return {
        isExactMatch: true,
        isCloseEnough: true,
      };
    } else {
      const target = 'france';
      const distance = levenshteinDistance(normalizedInput, target);

      return {
        isCloseEnough: distance <= 2,
        isExactMatch: distance === 0,
      };
    }
  };

  ScrollToTop();

  return (
    <div className="form-container pick-address">
      {isLoggedIn ? (
        // Logged-in user logic remains the same
        <>
          {addAddress ? (
            <div className="add-address-div">
              <AddAddress setAddAddress={setAddAddress} />
              <button
                className="form-submit-btn addressmanagement"
                onClick={handleAddAddress}
              >
                <FormattedMessage
                  id="addresscomponent.cancel"
                  defaultMessage="Cancel"
                />
              </button>
            </div>
          ) : (
            <div className="pick-addresses-div">
              <GenerateAddresses
                addressType={type}
                addresses={
                  type === 'shipping' ? shippingAddresses : billingAddresses
                }
                chosenAddress={chosenAddress}
                setChosenAddress={setChosenAddress}
              />
              <button
                className="form-submit-btn addressmanagement"
                onClick={handleAddAddress}
              >
                <FormattedMessage
                  id="addresscomponent.addaddress"
                  defaultMessage="Add an Address"
                />
              </button>
            </div>
          )}
        </>
      ) : (
        // Not logged-in user logic
        <div className="form-container edit-address">
          <form
            className="form-group edit-address"
            onSubmit={(e) => handleSubmit(e, !useDifferentBillingAddress)}
          >
            {addressType !== 'billing' ? (
              <React.Fragment>
                {' '}
                <div className="label-form-div edit-address">
                  <label
                    htmlFor="email"
                    className="form-label-edit-address"
                  >
                    <FormattedMessage
                      id="loginform.email"
                      defaultMessage="Email"
                    />
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleUserChange}
                    required
                    placeholder={intl.formatMessage({
                      id: 'loginform.email',
                      defaultMessage: 'Enter your email',
                    })}
                    autoComplete="email"
                  />
                </div>
                <div className="label-form-div edit-address">
                  <label
                    htmlFor="phone"
                    className="form-label-edit-address"
                  >
                    <FormattedMessage
                      id="signupform.phone"
                      defaultMessage="Phone"
                    />
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={user.phone}
                    onChange={handleUserChange}
                    required
                    placeholder={intl.formatMessage({
                      id: 'signupform.phone',
                      defaultMessage: 'Enter your phone number',
                    })}
                    autoComplete="tel"
                  />
                </div>
                {/* First Name */}
                <div className="label-form-div edit-address">
                  <label
                    htmlFor="firstName"
                    className="form-label-edit-address"
                  >
                    <FormattedMessage
                      id="signupform.firstname"
                      defaultMessage="First Name"
                    />
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={user.name}
                    onChange={handleUserChange}
                    required
                    placeholder={intl.formatMessage({
                      id: 'signupform.firstname',
                      defaultMessage: 'First Name',
                    })}
                    autoComplete="given-name"
                  />
                </div>
                {/* Last Name */}
                <div className="label-form-div edit-address">
                  <label
                    htmlFor="lastName"
                    className="form-label-edit-address"
                  >
                    <FormattedMessage
                      id="signupform.lastname"
                      defaultMessage="Last Name"
                    />
                  </label>
                  <input
                    type="text"
                    name="surname"
                    value={user.surname}
                    onChange={handleUserChange}
                    required
                    placeholder={intl.formatMessage({
                      id: 'signupform.lastname',
                      defaultMessage: 'Last Name',
                    })}
                    autoComplete="family-name"
                  />
                </div>
              </React.Fragment>
            ) : (
              ''
            )}
            {/* Address Line One */}
            <div className="label-form-div edit-address">
              <label
                htmlFor="lineOne"
                className="form-label-edit-address"
              >
                <FormattedMessage
                  id="addaddress.lineone"
                  defaultMessage="Line One"
                />
              </label>
              <AddressAutoComplete
                value={chosenAddress}
                onChange={handleAutoCompleteChange}
              />
            </div>
            {/* Address Line Two */}
            <div className="label-form-div edit-address">
              <label
                htmlFor="lineTwo"
                className="form-label-edit-address"
              >
                <FormattedMessage
                  id="addaddress.linetwo"
                  defaultMessage="Line Two"
                />
              </label>
              <input
                type="text"
                name="lineTwo"
                value={chosenAddress.lineTwo}
                onChange={handleAddressChange}
                placeholder={intl.formatMessage({
                  id: 'addaddress.placeholder.linetwo',
                  defaultMessage: 'Building number, floor, flat...',
                })}
                autoComplete="address-line2"
              />
            </div>
            {/* Postcode */}
            <div className="label-form-div edit-address">
              <label
                htmlFor="postCode"
                className="form-label-edit-address"
              >
                <FormattedMessage
                  id="addaddress.postcode"
                  defaultMessage="Zipcode"
                />
              </label>
              <input
                type="text"
                name="postCode"
                value={chosenAddress.postCode}
                onChange={handleAddressChange}
                required
                placeholder={intl.formatMessage({
                  id: 'addaddress.placeholder.postcode',
                  defaultMessage: 'Zipcode',
                })}
                autoComplete="postal-code"
                className={`${wrongZipCode ? 'error-input' : ''} `}
              />
            </div>
            {/* City */}
            <div className="label-form-div edit-address">
              <label
                htmlFor="city"
                className="form-label-edit-address"
              >
                <FormattedMessage
                  id="addaddress.city"
                  defaultMessage="City"
                />
              </label>
              <input
                type="text"
                name="city"
                value={chosenAddress.city}
                onChange={handleAddressChange}
                required
                placeholder={intl.formatMessage({
                  id: 'addaddress.placeholder.city',
                  defaultMessage: 'City',
                })}
                autoComplete="address-level2"
              />
            </div>
            {/* Country */}
            <div className="label-form-div edit-address">
              <label
                htmlFor="country"
                className="form-label-edit-address"
              >
                <FormattedMessage
                  id="addaddress.country"
                  defaultMessage="Country"
                />
              </label>
              <input
                type="text"
                name="country"
                value={chosenAddress.country}
                onChange={handleAddressChange}
                required
                placeholder={intl.formatMessage({
                  id: 'addaddress.placeholder.country',
                  defaultMessage: 'Country',
                })}
                autoComplete="country"
                className={`${wrongCountry ? 'error-input' : ''} `}
              />
            </div>
            {/* Use Different Billing Address */}
          </form>
        </div>
      )}{' '}
      {type === 'shipping' && (
        <div className="label-form-div different-billing-address">
          <label
            htmlFor="useDifferentBillingAddress"
            className="form-label-edit-address"
          >
            <FormattedMessage
              id="addresscomponent.billingdifferent"
              defaultMessage="Different billing address"
            />
          </label>
          <input
            type="checkbox"
            name="useDifferentBillingAddress"
            checked={useDifferentBillingAddress}
            onChange={(e) => setUseDifferentBillingAddress(e.target.checked)}
          />
        </div>
      )}{' '}
      {wrongAddress && wrongAddressMessage}
      <button
        className="basket-submit-btn"
        onClick={(e) => handleSubmit(e, !useDifferentBillingAddress)}
      >
        <FormattedMessage
          id="addresscomponent.next"
          defaultMessage="Next"
        />
      </button>
    </div>
  );
}
