import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const CookieConsent = ({ onAccept }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [essentialCookies, setEssentialCookies] = useState(true); // Always true, not changeable
  const [analyticsCookies, setAnalyticsCookies] = useState(true); // Default to true

  const intl = useIntl();

  // Handle acceptance of the current settings
  const handleAccept = (allCookies) => {
    const preferences = {
      essentialCookies: essentialCookies, // Always true
      analyticsCookies: allCookies ? true : analyticsCookies,
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    if (onAccept) {
      onAccept(preferences); // Call the callback function to inform parent component
    }
  };

  return (
    <div className="cookie-consent-modal">
      <div className="cookie-consent-banner">
        {!showDetails ? (
          <>
            <h4>
              <FormattedMessage
                id="cookieConsent.title"
                defaultMessage="Cookie Preferences"
              />
            </h4>
            <p>
              <FormattedMessage
                id="cookieConsent.description"
                defaultMessage="We use cookies to improve your experience. Customize your cookie preferences:"
              />
            </p>
            <button
              className="accept-essential"
              onClick={() => handleAccept(false)}
            >
              <FormattedMessage
                id="cookieConsent.acceptEssential"
                defaultMessage="Accept Only Essential Cookies"
              />
            </button>
            <button
              className="manage-settings"
              onClick={() => setShowDetails(true)}
            >
              <FormattedMessage
                id="cookieConsent.manage"
                defaultMessage="Manage Cookie Settings"
              />
            </button>{' '}
            <button
              className="accept-all"
              onClick={() => handleAccept(true)}
            >
              <FormattedMessage
                id="cookieConsent.acceptAll"
                defaultMessage="Accept All Cookies"
              />
            </button>
          </>
        ) : (
          <>
            <h4>
              <FormattedMessage
                id="cookieConsent.titleDetails"
                defaultMessage="Manage Cookie Preferences"
              />
            </h4>
            <p>
              <FormattedMessage
                id="cookieConsent.descriptionDetails"
                defaultMessage="We use cookies to improve your experience. Essential cookies are required for our payment partner, Stripe, and cannot be disabled. Analytics cookies are anonymous and allow us to better our website."
              />
            </p>
            <label>
              <FormattedMessage
                id="cookieConsent.essential"
                defaultMessage="Essential Cookies"
              />
              <input
                type="checkbox"
                checked={essentialCookies}
                readOnly // Essential cookies are always enabled
              />
            </label>
            <label>
              <FormattedMessage
                id="cookieConsent.analytics"
                defaultMessage="Analytics Cookies"
              />
              <input
                type="checkbox"
                checked={analyticsCookies}
                onChange={() => setAnalyticsCookies(!analyticsCookies)}
              />
            </label>
            <button
              className="accept-settings"
              onClick={() => handleAccept(false)}
            >
              <FormattedMessage
                id="cookieConsent.acceptSettings"
                defaultMessage="Accept Settings"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CookieConsent;
