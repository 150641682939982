import React from 'react';

import { FormattedMessage } from 'react-intl';

import ResetPassword from '../components/login/resetpwdcomponent';

export default function ResetPasswordPage() {
  return (
    <div className="content-div">
      <ResetPassword />
    </div>
  );
}
